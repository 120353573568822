import React, {useState} from 'react';
import { Row, Col } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { Modal } from 'react-bootstrap';
import { StarberryIcons } from "@components/icons/index";
import LocratingMap from '@components/map/loc-rating-map';
import { removeSpecialChar } from '../../common/utils'
import ResultTabs from "./ResultTabs";
import Button from "../../button/button"
// import Comparables from "./Comparables";
// import OfficeImg from "../../../images/office.jpg"
import { isEmpty } from 'lodash';
import "./result.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import _ from "lodash"
import EstimateValue from "../../../components/estimate-value/estimate-value"
import Calendar from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { postStbFormData } from "./../../../Forms/api/Api";
import moment from 'moment';
import {
  FOR_SALE_PAGE_URL,
  TO_RENT_PAGE_URL
} from "@components/common/site/constants"
import classNames from "classnames"
const Result = (props) => {
    var mapCoordinates = {
        lat: "51.5169",
        lng: "-0.104733"
    };

    const { leadProResult } = props;

    const address = leadProResult?.address || '';
    // if (!isEmpty(leadProResult?.comparable_props) && leadProResult?.comparable_props?.length > 0) {
    //     mapCoordinates.lat = leadProResult?.comparable_props[0].coords.y;
    //     mapCoordinates.lng = leadProResult?.comparable_props[0].coords.x;
    // }
    const [show, setShow] = useState(false);
    const [thankyou, setThanksyou] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [values, setValues] = useState([]);

    const appoinmentSubmit = () => {

        var dates = [];
        if (values) {
            _.map(values, (date) => {
                dates.push(moment(date?.toDate?.().toString()).utc().format())
            });
        }
        // console.log(dates)
        let formData = new FormData();
        var formValues = {
            "form_name": "leadpro-valuation",
            "form_type": "valuation",
            "form_label": "leadpro-valuation",
            "postcode": props.postcode,
            "address": props.address,
            "first_name": props.first_name,
            "last_name": props.last_name,
            "email": props.email,
            "telephone": props.phone,
            "bedrooms": props.property_bedrooms,
            "proprty_type": "Sell",
            "formname": "leadpro-valuation",
            "appointment_availability": {
                "hours": dates
            }
        }
        formData.append('data', JSON.stringify(formValues));
        postStbFormData(formData).then(async apiRes => {
            handleClose()
            setThanksyou(true);
            // props.setThanks(true)
        });
    }

    return (
        <div>
              {thankyou ? 
               <div className="small-holder mt-md-4">
               <h4 className="text-center body-l mb-0_8 mb-md-3 font-weight-semi c-grey-39">
               Thank you
               </h4>
               <p className="text-center pb-28">
                 <span>One of our property consultants will contact you shortly to confirm your appointment. In the meantime, if you are looking for your next home, you can browse through our selection of exquisite properties.</span>
               </p>
               <Button larger link={FOR_SALE_PAGE_URL?.alias} color="yellow" className={"mt-24 max-width-100"}>
               <Button.Text>
                 <span className="fz14">Find a Property</span>
               </Button.Text>
             </Button>
              {/* <Link class="max-width-100 mt-24 mb-24 btn btn--yellow fz14 fw-bolder"
                  to={FOR_SALE_PAGE_URL?.alias}
                >

                    Property for sale
                </Link> */}
 
             </div>
           :
           <div>
          <div className="small-holder mt-md-4">
            <h5 className="text-center body-l mb-0_8 mb-md-3 font-weight-semi c-grey-39">
              Valuation Report
            </h5>
            <h4 className="text-center">
              <span>{address}</span>
              {/* <span>Cathcart Road, London</span> */}
            </h4>
          </div>
            {
                        leadProResult &&
                        <ResultTabs
                            resultData={leadProResult}
                        />
                    }
          <div className="instant-valuation-intro__footer">
            <Button larger color="yellow" displayAsLink={false} onClick={handleShow} className={"max-width-100"}>
              <Button.Text>
                <span className="fz14">Book a valuation appointment</span>
              </Button.Text>
            </Button>
            <p className="mt-1_6 mt-md-2_4 mb-3">
              <strong className="font-weight-semi">
                Please note, this valuation is an estimate.
              </strong>{" "}
              This online valuation was generated automatically using Land
              Registry data and property listings data. It doesn't take into
              account any developments made to your property. For a more
              accurate valuation, please book in a valuation.
            </p>
          </div>
</div>}
          <Modal show={show} onHide={handleClose}
                backdrop="static"
                // centered
                // size="lg"
                dialogClassName="modal-appointment-form teamdetails popupForm"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Request a valuation appointment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className='form form--contact bg-white'>
                    <p className='pb-28'>Select as many times as you would like and we will contact you shortly to confirm your appointment.</p>
                    <Calendar
                        // ref={datePickerRef}
                        value={values}
                        onChange={setValues}
                        minDate={new Date()}
                        format="DD/MMM/YYYY hh:mm A"
                       
                        multiple
                        inputClass="form-control mt-24"
                        placeholder="Select appointment date"
                        plugins={[
                            <TimePicker position="bottom" hideSeconds className="valuation-time-picker"/>,
                            <DatePanel markFocused />
                        ]}
                    // onClose={() => false} 
                    />
                    <div className="form__footer">
                    <Button className="max-width-100 mt-24 mb-24 btn btn--blue fz14 fw-bolder" displayAsLink={false} onClick={() => { appoinmentSubmit() }}>Submit</Button>
                    </div>
                    </div>
                </Modal.Body>
            </Modal>
          </div>        
      
    )
}

export default React.memo(Result);