/**
 * External dependencies
 */
import React, { useMemo } from "react"

/**
 * Internal dependencies
 */
import useHomeGalleryCarousel from "./use-home-gallery-carousel"
import galleryData from "./static/gallery"
import Carousel from "../carousel/carousel"
import { convertGatsbyImageNodes } from "../../utils/gatsby-images"
import GalleryCard from "../card/gallery-card"
import "./styles/_index.scss"

const HomeGalleryCarousel = () => {
  const data = useHomeGalleryCarousel()
  const images = useMemo(() => convertGatsbyImageNodes(data), [data])
  const galleryCollection = useMemo(
    () =>
      galleryData.map(x => (
        <GalleryCard
          className={x.className}
          title={x.title}
          linkHref={x.link}
          children={x.children}
          alt={x.alt}
          image={images[x.image]}
          link={x.link ? true : false}
        />
      )),
    [images]
  )

  return (
    <div className="home-gallery-carousel pt-24 pb-24">
      <Carousel
        container="big"
        isFluidContainer={false}
        data={galleryCollection}
        controllers={{
          dots: true,
        }}
      />
    </div>
  )
}

export default HomeGalleryCarousel
