/**
 * External dependencies
 */
import React, { useState } from "react"
import { Row, Col, Container } from "react-bootstrap"
import { graphql } from "gatsby"
/**
 * Internal dependencies
 */
import {
  galleryData,
  galleryCardClassNames,
  galleryColSize2,
  galleryColSize3,
  galleryColSize4,
  galleryColSize5,
  galleryColSize5top,
  galleryColSize6
} from "./static/gallery"
import GalleryCard from "../card/gallery-card"
import useHomeGallery from "./use-home-gallery"
import "./styles/_index.scss"

const HomeGallery = (props) => {


  const data = useHomeGallery()
  // const src = getSrc(props?.banner_image?.url_sharp.childImageSharp)
  return (
    <div className={props?.Top_On ? "home-gallery top-on" : "home-gallery"}>
       
       {props?.Cap_Title || props?.Title ?
       <div className="home-news">
        
      <Container>
        {props?.Cap_Title &&
      <h6 className="home-news__heading fw-bolder fz14">{props?.Cap_Title}</h6>
        }
        {props?.Title &&
        <Row className="home-news__head-row g-0 align-items-md-center align-items-lg-start">
          <Col>
          <h3>{props?.Title}</h3>
          </Col>
          </Row>
}
          </Container>
          </div>
          : null}
      <Container className="container-big home-properties__inner">
     
        <Row className="g-0">
      
          {props?.Add_Services_Cta?.map((x, index) => (
            <Col
              xs={12}
              className={`home-gallery__col ${
                props?.Add_Services_Cta?.length === 2 ? galleryColSize2[index]
                : props?.Add_Services_Cta?.length === 3 ? galleryColSize3[index]
                : props?.Add_Services_Cta?.length === 4 ? galleryColSize4[index]
                : props?.Top_On && props?.Add_Services_Cta?.length === 5 ? galleryColSize5top[index]
                 : props?.Add_Services_Cta?.length === 5 ? galleryColSize5[index]
                  : props?.Add_Services_Cta?.length === 6 ? galleryColSize6[index]
                   : galleryColSize5[index]}`}
              key={index}
            >
              <GalleryCard
                className={galleryCardClassNames[index]}
                image={x.Image}
                alt={x.Image?.alternativeText?x.Image?.alternativeText+" - Strakers Estate Agents":"Gallery - Strakers Estate Agents"}
                title={x.Title}
                linktype={x.Services_Cta_Link?.Link_Type}
                linkHref={x.Services_Cta_Link?.Url?.Alias}
                linkText={x.Services_Cta_Link.Label}
                link={true}
                onImageHoverZoom
                openform={props?.openform}
                showForm={props?.showForm}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default HomeGallery

// export const servicesCtaFragment = graphql`
//     fragment ServicesCtaFragment on GLSTRAPI_ComponentComponentServicesCta {
//         id
//         Top_On
//         Cap_Title
//         Title
//         Add_Services_Cta {
//           Title
//           Services_Cta_Link {
//             Label
//             Link
//             Url {
//               Alias
//             }
//           }
//           Image {
//             alternativeText
//             url
//             url_sharp {
//               childImageSharp {
//                 gatsbyImageData(
//                   formats: WEBP
//                   width: 900
//                   quality: 100
//                   layout: CONSTRAINED
//                   transformOptions: { cropFocus: CENTER, fit: COVER }
//                 ) 
//               }
//             }
//           }
//         }
//     }
// `