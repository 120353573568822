import React, { useEffect, useState, useRef } from "react"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import TeamDetailInfo from "../components/team-detail-info/team-detail-info"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"
import Layout from "../components/layout"
import HomeBanner from "../components/home-banner/home-banner"
import logoBlack from "../images/svg/logo.svg"
import NotFoundPage from "../pages/404"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { useStaticQuery, graphql } from "gatsby"
import TeamBanner from "../components/home-banner/team-banner"
import OtherNewsSales from "../components/news-sales/other-news-sales"
import NewsBlockInfo from "../components/property-info/news-block-info"
import OtherNewsSlider from "../components/home-properties/other-news-slide"
const BlogDetail = props => {
  const newsdata = useStaticQuery(graphql`
  query {
    glstrapi {
      newsBanner(publicationState: LIVE) {
        News_Sidebar {
          Content
          Right_Sidebar_Bottom {
            Content
            Title
            Cta_Btn {
              Icon_Name
              Label
              Link
              Link_Type
              Url {
                Alias
              }
            }
          }
          Right_Sidebar_top {
            Title
            Cta_Buttons {
              Link_Type
              Icon_Name
              Label
              Link
            }
          }
        }
      }
    }
  }
`)
  const GET_VLOG_DETAILS = gql`
  query GetVlog($URL: String!) {
    videos(where: { URL: $URL }) {
      id
      imagetransforms
      Title
      URL
      Team_Member {
        id
          imagetransforms
          Name
          Email
          Designation
          Video_Url
          URL
          Image {
            alternativeText
            url
          }
      }
      Banner_Image{
        url
        alternativeText
      }
      Date
      Categories{
        Name
      }
      Video_Url
      Publish
     
    }
  }
`
  const { loading, error, data } = useQuery(GET_VLOG_DETAILS, {
    variables: { URL: props.slug },
    fetchPolicy: "no-cache"
  })
  let shareurl = ""
  if (typeof window !== "undefined") {
    shareurl = window.location.href ? window.location.href : ""
  }
  if (loading)
  return (
    <div className="static-news gql-loading-screen">
      <Container>
        <div className="gql-loading">
          <img
            className="logo-white loader-logo"
            src={logoBlack}
            alt="logo"
          />
        </div>
      </Container>
    </div>
  )

  return data?.videos?.length > 0 ? (
    <Layout
    >
      {data?.videos?.map((Page, i) => {
        let processedImages = JSON.stringify({})
        if (Page?.imagetransforms?.Image_Transforms) {
          processedImages = Page.imagetransforms.Image_Transforms
        }

        return (
          <>
            <Seo
              title={
                Page ? Page?.Title : ""
              }
              description={
                Page
                  ? "Get to know about " +
                  Page?.Title +
                  " here. Contact one of our estate agents for assistance in finding the right property for you."
                  : ""
              }
              image={Page?.Image?.url ? Page.Image.url : null}
            />
            <Helmet
              bodyAttributes={{
                class: `templates-blog-details-template team-page`,
              }}
            />

            <Breadcrumbs Page={Page?.Title} alias={"property-vlogs"} type={"news-details-page"} />
           <NewsBlockInfo newsdata={newsdata?.glstrapi?.newsBanner?.News_Sidebar} Pagename={Page?.Title}
           data={Page}
            />
                    
          </>
        )
      })}
    </Layout>
  ) : (
    <Layout className="blog-page">
      <Router>
        <Switch>
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </Layout>
  )

}

export default BlogDetail

