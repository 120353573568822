const news = [
  {
    image: 'img1',
    title: 'Escape to the Wiltshire Countryside',
    date: 'Fri 31 Dec 2021',
    category: 'Sales',
    href: '/'
  },
  {
    image: 'img2',
    title: 'Race For Space',
    date: 'Thu 30 Dec 2021',
    category: 'Community',
    href: '/',
    video: 'https://www.youtube.com/watch?v=ScMzIvxBSi4'
  },
  {
    image: 'img3',
    title: 'Thinking of waiting until New Year to sell your property?',
    date: 'Thu 09 Dec 2021',
    category: 'Sales',
    href: '/'
  },
  {
    image: 'img4',
    title: 'Environmentally Friendly Homes',
    date: 'Wed 27 Oct 2021',
    category: 'Lettings',
    href: '/',
    video: 'https://www.youtube.com/watch?v=ScMzIvxBSi4'
  },

];

export default news;