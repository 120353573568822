/**
 * External dependencies
 */
import React, { useState, useEffect, useMemo } from "react"
import axios from "axios";

import { Link } from "gatsby"
import { Row, Col, Container } from "react-bootstrap"
import { currencyFormat } from '@components/common/utils';
/**
 * Internal dependencies
 */
import useHomeProperties from "./use-home-properties"
import { convertGatsbyImageNodes } from "../../utils/gatsby-images"
import Card from "../card/card"
import LinkIcon from "../link-icon/link-icon"
import properties from "./static/properties"
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
import "./styles/_index.scss"
import Carousel from "../carousel/carousel"
import classNames from "classnames"
import {
  FOR_SALE_PAGE_URL,
  TO_RENT_PAGE_URL
} from "@components/common/site/constants"
import {
  isMobile
} from "react-device-detect";
import Modal from 'react-bootstrap/Modal';
import PropertyEnquireForm from "../../Forms/PropertyEnquire";
const LandProperties = (props) => {
  const [showForm, setShowForm] = useState(false);
  const [index, setIndex] = useState();
  const openform = (x) => {
            setShowForm(!showForm)
          setIndex(x)
  }
  const [propItems, setPropItems] = useState([])
  const [isCLient, setClent] = useState(true)

  const getitems = async url => {
    try {
      const { data } = await axios.get(url, {baseURL: process.env.GATSBY_STRAPI_FORM_URL,
        headers: {
          Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
          // "Content-Type": "multipart/form-data"  
        }})
      // console.log("PropertySimilar", data)
      setPropItems(data)
      setClent(false)
    } catch (error) {
      // console.error(error)
    }
  }

  useEffect(() => {
    let url;
    if(props?.listname === "land"){
      url = process.env.GATSBY_STRAPI_SRC +
      "/stb-lists/item/land_slider"
      // ?_limit=8&_sort=price:DESC&publish=true
      getitems(url)
    }
    else if(props?.listname === "sold_land"){
      // url = process.env.GATSBY_STRAPI_SRC +
      // "/stb-lists/item/sold_land_slider"
      debugger
      setPropItems(props?.Add_Item)
      setClent(false)
    }

   
  }, []);

  // const data = useHomeProperties()
  // const images = useMemo(() => convertGatsbyImageNodes(data), [data])
  const propertiesCollection = useMemo(
    () =>
      propItems?.map((x, i) => {
        let uriStr = ""
        let mysale = ""
        let hit = { ...x }
        // mapping
        hit.searchType = hit.searchType || hit.search_type;

        if (hit.searchType === "sales" && hit.department === "residential") {
          uriStr = `property-for-sale/`
          mysale = `myResSaleProp`
        } else if (
          hit.searchType === "lettings" &&
          hit.department === "residential"
        ) {
          uriStr = `property-to-rent/`
          mysale = `myResRentProp`
        }

        let processedImages = JSON.stringify({});
        if (x.imagetransforms?.images_Transforms) {
          processedImages = x.imagetransforms?.images_Transforms;
        }
        return (
          props?.listname === "sold_land" ?
          <Card>
          {/* <div className={"property-box__badge yellow"}>Sold Subject To Contract</div> */}
            {/* <Card.Head onImageHoverZoom image={images[x.image]} alt={x.alt || "property"} /> */}
            <div className="overflow-hidden">
              <div className="hover-zoom">
           <img src={x.Image?.url} alt={x.Image?.alternativeText} />
            </div>
            </div>
          
          <Card.Body>
              <h5>{x.Title}</h5>
              

            <div className="card__description d-flex">
            {x?.price_qualifier !== "Default" && x?.price_qualifier &&
              <span className="card__subtitle fz14 d-inline-block">
                {x?.price_qualifier !== "Default" ? x?.price_qualifier : ""}
              </span>
    }
              <span className="fw-bolder card__price fz14">{x?.Price}</span>
            </div>

         
          </Card.Body>
         
          
        </Card>
        :
          <Card>
            <Link to={`/${uriStr}${x.slug}-${x.id}`}>
            <div className={hit?.status === "Sold Subject To Contract" || hit?.status === "Let Agreed" ? "property-box__badge yellow" : "property-box__badge"}>{x?.status}</div>
              {/* <Card.Head onImageHoverZoom image={images[x.image]} alt={x.alt || "property"} /> */}
              <div className="overflow-hidden">
                <div className="hover-zoom">
              <ImageTransform
                imagesources={x?.images[0]?.url}
                // renderer="srcSet"
                imagename="property.images.similardetail"
                attr={{ alt: `${x.display_address?x.display_address:""} - Strakers Estate Agents`, className: "" }}
                imagetransformresult={processedImages}
                id={x.id}
              />
              </div>
              </div>
            </Link>
            <Card.Body>
              <Link className="address-min-height" to={`/${uriStr}${x.slug}-${x.id}`}>
                <h5>{x.display_address}</h5>
                <h5>{x.area}</h5>
              </Link>

              <div className="card__description d-flex">
              {x?.price_qualifier !== "Default" && x?.price_qualifier &&
                <span className="card__subtitle fz14 d-inline-block">
                  {x?.price_qualifier !== "Default" ? x?.price_qualifier : ""}
                </span>
      }
                <span className="fw-bolder card__price fz14">{currencyFormat(x?.price, '£', false)}</span>
              </div>

              <p>{x?.title?.replace("land", "Land")?.replace(/-/g, ' ')}</p>
            </Card.Body>
            <Card.Footer>
              <Row className="g-0 flex-nowrap justify-content-between">
                <Col>
                  <Link class="link-icon blue-link-hover d-flex align-items-center"
                    to={`/${uriStr}${x.slug}-${x.id}`}
                  >
                    <div
                      className={`link-icon__text fw-bolder fz14 position-relative order-0`}
                    >
                      Details
                    </div>
                    <i className={classNames(`icon icon--eye`)}></i>
                  </Link>
                  {/* <LinkIcon text="Details" icon="eye" /> */}
                </Col>

                <Col>
                  <Card.Delimiter />
                </Col>

                <Col>
                  <a class="link-icon blue-link-hover d-flex align-items-center"
                  // to={`/${uriStr}${hit.slug}-${hit.id}`}
                  onClick={(e) => openform(x)}
                  href="javascript:void(0)"
                  >
                    <div
                      className={`link-icon__text fw-bolder fz14 position-relative order-0`}
                    >
                      Enquire
                    </div>
                    <i className={classNames(`icon icon--arrow-up-right`)}></i>
                  </a>
                  {/* <LinkIcon text="Enquire" /> */}
                </Col>
              </Row>
            </Card.Footer>
            
          </Card>
        )
      }),
    [propItems]
  )

  

  return (
    propertiesCollection?.length > 0 ?
    <div className={props?.listname === "sold_land" ? "home-properties home-developements border-top pt-48 pb-48 pt-120 pb-120" : "home-properties home-developements pt-48 pb-48 pt-120 pb-120"}>
      <Container className="home-properties__inner">
        <h6 className="home-properties__quote fw-bolder fz14 d-inline-block">
          {props?.listname === "sold_land" ? props?.Title : props.Cap_Title}
        </h6>
        
        <Row className="align-items-center align-items-lg-start justify-content-lg-between">
          <Col xs={12} lg="auto">
            <h3>{props?.listname === "sold_land" ? props?.Big_Title : props.Title}</h3>
          </Col>
          {props?.listname === "sold_land" ? ""
          :
          <Col lg="auto">
            <Row className="flex-nowrap home-properties__actions">
              <Col xs="auto" className="home-properties__action-col-1">
                <Link class="link-icon blue-link-hover d-flex align-items-center"
                  to={FOR_SALE_PAGE_URL?.alias}
                >
                  <div
                    className={`link-icon__text fw-bolder fz14 position-relative order-0`}
                  >
                    View All
                  </div>
                  <i className={classNames(`icon icon--arrow-up-right`)}></i>
                </Link>
                {/* <LinkIcon href="/" text="Property for sale" /> */}
              </Col>

              
            </Row>
          </Col>
          }
        </Row>
      </Container>
      {/* {JSON.stringify(propItems)} */}
      {propertiesCollection?.length > 0 &&
      <Carousel
        className="home-properties__collection"
        data={propertiesCollection}
        controllers={{ buttons: isMobile && propertiesCollection?.length > 1 ? true : !isMobile && propertiesCollection?.length > 3 ? true : false }}
        isFluidContainer={false}
      />}
      
        <Modal className="teamdetails popupForm" backdrop="static" size="lg" show={showForm} onHide={() => { setShowForm(false);setIndex() }}>
                                            <Modal.Header closeButton>
                                                <Modal.Title> <div className="form__head">
          <h4>Enquire about Property</h4>
          {/* {JSON.stringify(index)}
          {index?.searchtype} */}
        </div>
</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body> 
                                                <PropertyEnquireForm
                                                id={index?.id}
                                                 property_img={index?.images && index?.images[0]?.url}
                                                  page_url={`${process.env.GATSBY_SITE_URL}${index?.search_type === "sales" && index?.department === "residential" ? "property-for-sale/" : "property-to-rent/"}${index?.slug}-${index?.id}`}
                                                   property_title={index?.display_address}
                                                    type={index?.search_type}
                                                data={index}
                                                />  
                                            </Modal.Body>
</Modal>
    </div>
 : isCLient ? <div className="empty-space"><br/></div>
 : null )
}

export default LandProperties
