/**
 * External dependencies
 */
import React from "react"
import { Col, Form, Row } from "react-bootstrap"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import DatePicker from "react-datepicker"

/**
 * Internal dependencies
 */
import "react-datepicker/dist/react-datepicker.css"
import "./styles/_index.scss"
import FieldSelect from "../../components/field-select/field-select"

const schema = yup.object({
  first_name: yup.string().required("Required"),
  last_name: yup.string().required("Required"),
  email: yup.string().email().required("Required"),
  notes: yup.string().required("Required"),
})

const ValuationForm = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      postcode: "",
      date: "",
      time: "",
      "buy-sell": "",
    },
  })
  const onSubmit = data => console.log(data)

  const handleError = name =>
    errors[name] && (
      <div className="error mt-2">
        <small className="text-danger">{errors[name].message}</small>
      </div>
    )

  return (
    <Form className="valuation-form" onSubmit={handleSubmit(onSubmit)}>
      <Row className="flex-md-nowrap">
        <Col xs="12" md="6" className="flex-shrink-1 pe-md-4 mb-2_75 mb-md-3_4">
          <Form.Group>
            <Form.Label>First Name</Form.Label>
            <Form.Control type="text" {...register("first_name")} />
            {handleError("first_name")}
          </Form.Group>
        </Col>
        <Col xs="12" md="6" className="flex-shrink-1 ps-md-4 mb-2_75 mb-md-3_4">
          <Form.Group>
            <Form.Label>Last Name</Form.Label>
            <Form.Control type="text" {...register("last_name")} />
            {handleError("last_name")}
          </Form.Group>
        </Col>
      </Row>
      <Row className="flex-md-nowrap">
        <Col xs="12" md="6" className="flex-shrink-1 pe-md-4 mb-2_75 mb-md-3_4">
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control type="text" {...register("email")} />
            {handleError("email")}
          </Form.Group>
        </Col>
        <Col xs="12" md="6" className="flex-shrink-1 ps-md-4 mb-2_75 mb-md-3_4">
          <Form.Group>
            <Form.Label>Phone</Form.Label>
            <Form.Control type="text" {...register("phone")} />
            {handleError("phone")}
          </Form.Group>
        </Col>
      </Row>
      <Row className="flex-md-nowrap">
        <Col
          xs="12"
          md="6"
          className="flex-shrink-1 pe-md-4  mb-2_75 mb-md-3_4"
        >
          <Form.Group>
            <Form.Label>Property Postcode</Form.Label>
            <Form.Control type="text" {...register("postcode")} />
            {handleError("postcode")}
          </Form.Group>
        </Col>
        <Col
          xs="12"
          md="6"
          className="flex-shrink-1 ps-md-4  mb-2_75 mb-md-3_4"
        >
          <Controller
            render={({ field }) => (
              <Form.Group>
                <Form.Label>
                  Do you want to Sell or Let the property?
                </Form.Label>
                <FieldSelect
                  isSearchable={false}
                  field={field}
                  fontSize="1.6rem"
                  borderRadius="4px"
                  options={[
                    {
                      value: "Sell",
                      label: "Sell",
                    },
                    {
                      value: "Buy",
                      label: "Buy",
                    },
                  ]}
                />
              </Form.Group>
            )}
            control={control}
            name="buy-sell"
          />
        </Col>
      </Row>
      <Row className="flex-md-nowrap">
        <Col xs="12" md="6" className="flex-shrink-1 pe-md-4 mb-2_75 mb-md-3_4">
          <Controller
            control={control}
            name="date"
            render={({ field }) => (
              <Form.Group>
                <Form.Label>Preferred Date</Form.Label>
                <DatePicker
                  className="form-control form-control--date"
                  onChange={date => field.onChange(date)}
                  selected={field.value}
                />
              </Form.Group>
            )}
          />
        </Col>
        <Col xs="12" md="6" className="flex-shrink-1 ps-md-4 mb-2_75 mb-md-3_4">
          <Controller
            control={control}
            name="time"
            render={({ field }) => (
              <Form.Group>
                <Form.Label>Preferred Time</Form.Label>
                <DatePicker
                  className="form-control form-control--time"
                  onChange={date => field.onChange(date)}
                  selected={field.value}
                  showTimeSelect
                  showTimeSelectOnly
                  dateFormat="h:mm aa"
                />
              </Form.Group>
            )}
          />
        </Col>
      </Row>
      <Form.Group>
        <Form.Label>Notes</Form.Label>
        <Form.Control
          as="textarea"
          {...register("notes")}
          placeholder="Please share any additional information on the property"
        />
      </Form.Group>

      <div className="form__actions">
        <button type="submit" className="btn is-larger btn--yellow body-l">
          <strong>Submit Details</strong>
        </button>
      </div>
    </Form>
  )
}

export default ValuationForm
