/**
 * External dependencies
 */
import React , {useMemo} from "react"
import { Container, Row, Col } from "react-bootstrap"
import classNames from "classnames"
import { Link } from "gatsby"
/**
 * Internal dependencies
 */
import lands from "./static/lands"
import CardLand from "./card-land"
import useNewsLand from "./use-news-land"
import { convertGatsbyImageNodes } from "../../utils/gatsby-images"
import "./styles/_index.scss"
import { useStaticQuery, graphql } from "gatsby";
import {
  REVIEWS_PAGE_URL
} from "../common/site/constants"
import {
  isIOS,
  isMobile
} from "react-device-detect";
import Carousel from "../carousel/carousel"
const StaffVideos = ({Choose_Staff, Cap_Title, Title}) => {
  const propertiesCollection = useMemo(
    () =>
    Choose_Staff?.map((x, index) => {
      let processedImages = JSON.stringify({})
      if (x.imagetransforms?.Image_Transforms) {
        processedImages = x.imagetransforms.Image_Transforms
      }
  
        return (
          <Col
            key={x.Name}
            className={classNames("news-land__col", {
              // "d-none d-lg-block": index >= 2,
            })}
          >
            <CardLand
            isReview={true}
              imagetransforms={processedImages}
              id={x.id}
                image={x.Image}
                video={x.Video_Url}
                title={x.Name}
                // href={x.URL}
                designation={x.Designation}
                index={index}
                imgname={"team.Image.list"}
            />
          </Col>
        )
      }),
    [Choose_Staff]
  )
  return (Choose_Staff?.length > 0 ?
    <div className="home-news staff-videos-blk pt-48 pb-48 pt-120 pb-120">
    <Container>
        {Cap_Title &&<h6 className="home-news__heading fw-bolder fz14">{Cap_Title}</h6>}
        <Row className="home-news__head-row g-0 align-items-md-center align-items-lg-start">
          <Col>
          <h3>{Title}</h3>
          </Col>

          {/* <Col xs={12} md="auto">
          <Link             className="link-icon blue-link-hover d-flex align-items-center"
                            
                            to={REVIEWS_PAGE_URL.alias}
                          >
                             <div
      className={`link-icon__text fw-bolder fz14 position-relative order-0`}
    >
      View Our Offices
    </div>
    <i className={classNames(`icon icon--arrow-up-right`)}></i>
                          </Link>
          </Col> */}
        </Row>
        </Container>
    <Container className="">
      <Row className="news-land__row flex-column flex-md-row">
      {Choose_Staff?.length > 0 &&
                <Carousel
                className="news-land__row staff-videos-slider flex-column flex-md-row"
                data={propertiesCollection}
                controllers={{ buttons: isMobile && Choose_Staff?.length > 1 ? true : !isMobile && Choose_Staff?.length > 4 ? true : false }}
                isFluidContainer={false}
              />
        }
      </Row>
    </Container>
    </div>
  : null)
}

export default StaffVideos
export const staffVideosFragment = graphql`
    fragment StaffVideosFragment on GLSTRAPI_ComponentComponentStaffVideos {
      id
          Cap_Title
          Title
          Choose_Staff {
            Designation
            Video_Url
            Image {
              alternativeText
              url
            }
            imagetransforms
            id
            Name
            Publish
            URL
          }

    }
`