/**
 * External dependencies
 */
import React from "react"

/**
 * Internal dependencies
 */
import Button from "../../button/button"

const gallery = [
  {
    className:
      "gallery-card--feed bg-blue text-white justify-content-center align-items-center",
    children: (
      <>
        <i className="icon icon--instagram bg-white d-block mx-auto" />
        <h5>Strakers Instagram</h5>
        <span className="gallery-card__subtitle">@strakers_estate_agents</span>
        <Button
          color="yellow"
          isExternalLink={true}
          href="https://www.instagram.com/"
        >
          <Button.Text>Follow</Button.Text>
        </Button>
      </>
    ),
  },
  {
    image: "img2",
    link: "/",
  },
  {
    image: "img3",
    link: "/",
  },
  {
    image: "img4",
    link: "/",
  },
  {
    image: "img5",
    link: "/",
  },
  {
    image: "img2",
    link: "/",
  },
  {
    image: "img3",
    link: "/",
  },
  {
    image: "img4",
    link: "/",
  },
  {
    image: "img5",
    link: "/",
  },
]

export default gallery
