import React, { useState } from "react";
import { StarberryIcons } from "@components/icons/index";
import Autosuggest from 'react-autosuggest';
import { ApiRequest } from "@utils";
import "./postcodeField.scss";
import _ from "lodash" 
const PostcodeField = (props) => {
    const resultFormation = (suggestion) => {
        var address = `${suggestion.line_1}`;
        if (suggestion.line_2) {
          address += ` ${suggestion.line_2}`;
        }
        if (suggestion.line_3) {
          address += `, ${suggestion.line_3}`;
        }
        if (suggestion.post_town) {
          address += `, ${_.capitalize(suggestion.post_town)}`;
        }
        if (suggestion.postal_county) {
          address += `, ${_.capitalize(suggestion.postal_county)}`;
        }
        return address;
      }
    const [ suggestions, setSuggestions ] = useState([]);

    const [ location, setLocation ] = useState(props?.value ? props?.value : '');
 
    const renderSuggestion = suggestion => {

        var address = resultFormation(suggestion);
        return (
          <div className="post-code-suggestion">
            <p className="mb-0"> {address}</p>
          </div>
        )
      };

    const onChangeLocation = (event, { newValue }) => { 
        setLocation(newValue);
        props.selectHandelChange('postcode', newValue)
    }; 

    const getSuggestionSelected = (event, { suggestion}) => {
        props.postcodeChange(suggestion);
    }; 

    const getSuggestionValue = suggestion => {
        var address = resultFormation(suggestion);
        return address;
      }
    const onSuggestionsClearRequested = () => {
        setSuggestions([])
    };

    const onSuggestionsFetchRequested = ({ value }) => { 
        // 'EC1A 2BN'
        ApiRequest({
            method: "GET",
            url: 'https://leadpro-backend-production.herokuapp.com/ivt/addresses',
            header: { Authorization: '554a4564-9afa-4842-80ba-9697fbd37653' },
            body: { postcode: value }
        }, (result) => { 
            setSuggestions(result.data)
        })       
    };

    const inputProps = {
        value: location,
        onChange: onChangeLocation,
        type: "search",
        placeholder: props.placeholder
    };

    const { label, inlineIcon = '', className } = props;

    return (
        <div
            className={`postcode-field-wrapper ${inlineIcon ? 'has-inline-icon' : ''} ${className || ''}`}
        >
            {
                inlineIcon && (
                    <StarberryIcons
                        iconName={inlineIcon}
                        className="inline-icon"
                    />
                )
            } 
             {
                label && (
                    <label className='form-label'>
                        {label}
                    </label>
                )
            }
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                onSuggestionSelected={getSuggestionSelected}
                inputProps={inputProps}
                // value={location}
            />
        </div>
    )
}

export default PostcodeField
