/**
 * External dependencies
 */
import React, {useState, useEffect} from "react"
import { Container, Row, Col } from "react-bootstrap"
import HiddenText from "../hidden-text/hidden-text"
import LocRatingMap from '../map/loc-rating-map';
/**
 * Internal dependencies
 */
import PropertyCallout from "../property-callout/property-callout"
import PropertyIntro from "../property-intro/property-intro"
import PropertySchema from "../property-schema/property-schema"
import PropertyCalculator from "../property-calculator/property-calculator"
import Accordions from "../accordion/accordion"
import usePropertyInfo from "./use-property-info"
import "./styles/_index.scss"
import { convertGatsbyImageNodes } from "../../utils/gatsby-images"
import ContentBlock from "../content/Content";
import TermsCallout from "../property-callout/terms-callout";

const TermsBlockInfo = (props) => {
  // console.log("text", props)
    // 
    const [scroll, setScroll] = useState(false)

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 350)
        })
    },[])
    // 
  return (
    <div className="property-info-wrapper terms-blk">
      <Container className="property-info">
        <Row>
          <h1>{props.Pagename}</h1>
        </Row>
        <Row className="flex-column flex-lg-row justify-content-lg-between">
          <Col className="property-info__content  pt-32 pb-32">
            <ContentBlock
              // title="Property Description"
              Content={props?.Content}
            />
            {props?.Toggle_Block?.length > 0 &&
           <>
          <div className="area-guide-info__accordions border-top">
            <Accordions>
              {props?.Toggle_Block?.map((x, index) => (
                <Accordions.Item eventKey={index} title={x.Title} key={index}>
                  <ContentBlock Content={x.Content} />
                </Accordions.Item>
              ))}
            </Accordions>
          </div>
          </>
          }
          <div className="content-top">
          <ContentBlock
              // title="Property Description"
              Content={props?.Add_Content}
            />
</div>
          </Col>
          {props?.Right_Sidebar_Bottom || props?.Right_Sidebar_top ?
          <Col className="property-info__callout" 
          // lg="auto"
          >
          <div className={`sticky-top ${scroll ? "scrolled" : ""}`}>
            <TermsCallout
              Right_Sidebar_Bottom={props?.Right_Sidebar_Bottom}
              Right_Sidebar_top={props?.Right_Sidebar_top}
            />
            </div>
          </Col>
           : null}
        </Row>
      </Container>
    </div>
  )
}

export default TermsBlockInfo
