/**
 * External dependencies
 */
import React, { useState } from "react"
import classNames from "classnames"
import { Row, Col } from "react-bootstrap"
import ContentBlock from "../content/Content";
/**
 * Internal dependencies
 */
import "./styles/_index.scss"
import CountUp, { useCountUp } from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
const BulletInfo = ({ icon, title, description, Prefix, Suffix, inView }) => {
  const COUNT_UP_START = 0;
  const COUNT_UP_DURATION = 3;
  const [viewPortEntered, setViewPortEntered] = useState(false);
  return (
    <Row className="bullet-info g-0">
      <Col
        xs="auto"
        className="bullet-info__icon d-flex justify-content-center align-items-center"
      >
        <i className={classNames(`icon icon--`)}
          style={{ backgroundImage: `url(${icon?.url})` }}
        />
      </Col>

      <Col className="bullet-info__text">
        <h3>{Prefix? Prefix : ""} <CountUp
          start={inView ? COUNT_UP_START : title}
          duration={COUNT_UP_DURATION}
          end={title} delay={0}
        > 
        
          {/* {({ countUpRef }) => {
            return (
              <VisibilitySensor
                active={!viewPortEntered}
                onChange={isVisible => {
                  if (isVisible) {
                    setViewPortEntered(true);
                  }
                }}
                delayedCall
              >
                <span ref={countUpRef}/>
              </VisibilitySensor>
            );
          }} */}
        </CountUp>
        {Suffix ? Suffix : ""}</h3>
        <ContentBlock Content={description} />

      </Col>
    </Row>
  )
}
export default BulletInfo
