/**
 * External dependencies
 */
 import React, { useState, useRef, createRef } from 'react';
import { Link } from "gatsby"
import { Row, Col, Form, Button } from "react-bootstrap"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import PostcodeField from "@components/formComponents/postcode";
import { isEmpty, get, find, includes } from "lodash"
/**
 * Internal dependencies
 */
import { branchOptions, departmentOptions } from "./static/form-data"
import "./styles/_index.scss"
import FieldSelect, {
  customStyles,
} from "../../components/field-select/field-select"
import TextField from "../../Forms/Inputs/TextField";
import TextArea from "../../Forms/Inputs/TextArea";
import Recaptcha from "../../Forms/Inputs/Recaptcha";
import SelectBox from "../../Forms/Inputs/SelectBox"
import { postFormData } from "../../Forms/api/Api";
import { ScrollToTop, SendMail, FormValidation, gtmEvent, toCapitalize } from "../../components/common/utils";
import _ from "lodash";
import "../../Forms/Form.scss";
import { useStaticQuery, graphql } from "gatsby";
const schema = yup.object({
  branch: yup.string().required("Required"),
  department: yup.string().required("Required"),
  "first-name": yup.string().required("Required"),
  "last-name": yup.string().required("Required"),
  email: yup.string().required("Required"),
  phone: yup.string(),
  message: yup.string().required("Required"),
})

const SellLetForm = (props) => {
  const inputRef = useRef(null);
    const data = useStaticQuery(graphql`
    query{
      glstrapi {
        offices(sort:"Sort:ASC", publicationState: LIVE, where: {Publish: true}) {
          Office_Name
          Office_Email
        }
      }
    }
    `);
  // const {
  //   register,
  //   handleSubmit,
  //   control,
  //   formState: { errors },
  // } = useForm({
  //   resolver: yupResolver(schema),
  //   defaultValues: {
  //     branch: "Chippenham",
  //     department: "Sales",
  //     "first-name": "",
  //     "last-name": "",
  //     email: "",
  //     phone: "",
  //     message: "",
  //   },
  // })
  // const onSubmit = data => console.log(data)

  // const handleError = name =>
  //   errors[name] && (
  //     <div className="error mt-2">
  //       <small className="text-danger">{errors[name].message}</small>
  //     </div>
  //   )

var options = [];
 data?.glstrapi?.offices?.map((item) =>{
    options.push({
        label: toCapitalize(item.Office_Name), value: item.Office_Name 
    })
  })
  const myRef = createRef(null);

    const recaptchaRef = useRef();
    const initFormState = {
        formname: "Sell or Let your Home",
        branch: props.branch ? props.branch : 'Chippenham',
        department: 'Sales',
        fname: '',
        lname: '',
        email: '',
        postcode: '',
        telephone: '',
        message: ''
    }
    const validationFields = ['fname', 'lname', 'email', 'telephone', 'postcode'];
    const [formState, setFormState] = useState(initFormState);
    const [errors, setErrors] = useState({});
    const [errorFlag, setErrorFlag] = useState(false);
    const [showthankyou, setThankyou] = useState(false);
    const [btnProcess, setBtnProcess] = useState(false);
    const handleChange = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value,
        });
    }
    const postcodeChange = (suggestion) => {
      var selected_address = suggestion.line_1 ? suggestion.line_1+", " : "";
      selected_address += suggestion.line_2 ? suggestion.line_2+", " : "";
      selected_address += suggestion.line_3 ? suggestion.line_3+", " : "";
      selected_address += suggestion.postcode ? suggestion.postcode+", " : "";
      setFormState({
        ...formState,
        // location: suggestion,
        address: selected_address,
        postcode: suggestion.postcode,
      });
    }
    const selectHandelChange = (name, value) => {
      sessionStorage.setItem(name, value);
  
      setFormState({
        ...formState,
        [name]: value
      });
    }
    const handleChangeBranch = (e) => {
      setFormState({
          ...formState,
          "branch": e.label,
      });
  }
  const handleChangeDep = (e) => {
    setFormState({
        ...formState,
        "department": e.label,
    });
}
    const handleSubmit = async (event) => {
        event.preventDefault();
        var validation = FormValidation(formState, validationFields);
        if (validation.status) {
            setErrorFlag(false)
            setErrors(validation.errors)
            const token = await recaptchaRef.current.executeAsync();
            if (token) {
                setBtnProcess(true)
                var postData = _.cloneDeep(formState)
                postData['extra'] = formState;
                recaptchaRef.current.reset();
                postFormData(postData).then(async apiRes => {
                    setThankyou(true)
                    setFormState(initFormState)
                    // document.querySelector('.react-autosuggest__input').value = ""
                    setBtnProcess(false);
                    // scrollTop();
                    myRef?.current?.scrollIntoView({ behavior: 'smooth'})
                    setTimeout(() => {
                        setThankyou(false)
                    }, 9000)
                    // GTM tracking event 
                    gtmEvent({ formType: 'Sell or Let Form', formName: 'Sell or Let Form' })
                    postData['to_admin'] = data.glstrapi?.offices?.filter(list => list.Office_Name?.includes(formState?.branch?.toLowerCase()))[0]?.Office_Email?.toLowerCase()
                    // lets send mail
                    // if(formState?.branch?.toLowerCase() === "chippenham"){
                    //   postData['to_admin'] = "chippenham@strakers.co.uk";
                    // }
                    // else if(formState?.branch?.toLowerCase() === "devizes"){
                    //   postData['to_admin'] = "devizes@strakers.co.uk";

                    // }
                    // else if(formState?.branch?.toLowerCase() === "malmesbury"){
                    //   postData['to_admin'] = "malmesbury@strakers.co.uk";
                      
                    // }
                    // else if(formState?.branch?.toLowerCase() === "corsham"){
                    //   postData['to_admin'] = "corsham@strakers.co.uk";
                      
                    // }
                    // else if(formState?.branch?.toLowerCase() === "lettings head office"){
                    //   postData['to_admin'] = "lettings@strakers.co.uk";
                      
                    // }
                    // else if(formState?.branch?.toLowerCase() === "auctions"){
                    //   postData['to_admin'] = "auctions@strakers.co.uk";
                      
                    // }
                    // else if(formState?.branch?.toLowerCase() === "property management"){
                    //   postData['to_admin'] = "propertymanagement@strakers.co.uk";
                      
                    // }
                    // else if(formState?.branch?.toLowerCase() === "formal valuations & advice"){
                    //   postData['to_admin'] = "richard.newsome@strakers.co.uk";
                      
                    // }
                    // else if(formState?.branch?.toLowerCase() === "sales progression"){
                    //   postData['to_admin'] = "salesprogression@strakers.co.uk";
                      
                    // }
                    postData['email_temp_user'] = 'sell_let_user';
                    postData['email_temp_admin'] = 'sell_let_admin';
                    postData['email_subject_user'] = 'Strakers - Sell or Let Your Home Enquiry';
                    postData['email_subject_admin'] = 'Strakers - Sell or Let Your Home Enquiry';
                    await SendMail(postData);
                });
            }
        } else {
            setErrors(validation.errors)
            setErrorFlag(true)
            // scrollTop();
            myRef?.current?.scrollIntoView({ behavior: 'smooth'})
        }
    }

    const scrollTop = () => {
        ScrollToTop(document.getElementById('form-section'), 100)
    }
    const errClass = (field) => {
        if (errors[field]) {
            return 'field-error';
        }
        return ''
    }

  return (
    <section className='form-section scroll-margin-top' id="form-section"  ref={myRef}>
    <div className="form form--contact bg-white">
        {props?.popup ?
        <></> :
        <div className="form__head">
          <h4>How can we help</h4>
        </div>
}

        <div className="form__body">
          <div className="form-row">
            <Row>
            <Col md={12}>
                                        {showthankyou && <div className="alert alert-success">
                                            Thank you for your interest. A member of our team will contact you shortly.
                                        </div>}
                                        {
                                            errorFlag &&
                                            <div className="alert alert-danger alert-error">Highlighted fields are required | invalid</div>
                                        }

                                        <div className='form-inputs'>
                                        <Row>
                                      

    <Col md={6}>
                                            <TextField
                                                label="First Name"
                                                name={`fname`}
                                                placeholder=""
                                                onChange={handleChange}
                                                value={formState[`fname`]}
                                                className={errClass(`fname`)}
                                            />
                                            </Col>
                                            <Col md={6}>
                                            <TextField
                                                label="Last Name"
                                                name={`lname`}
                                                placeholder=""
                                                onChange={handleChange}
                                                value={formState[`lname`]}
                                                className={errClass(`lname`)}
                                            />
                                            </Col>
                                            </Row>
                                            <Row>
                                            <Col md={6}>
                                            <TextField
                                                label="Email Address"
                                                name={`email`}
                                                placeholder=""
                                                onChange={handleChange}
                                                className={errClass(`email`)}
                                                value={formState[`email`]}
                                            />
                                            </Col>
                                            <Col md={6}>
                                            <TextField
                                                type="number"
                                                label="Telephone"
                                                name={`telephone`}
                                                placeholder=""
                                                onChange={handleChange}
                                                className={errClass(`telephone`)}
                                                value={formState[`telephone`]}
                                            />
                                            </Col>
                                            <Col md={6}>


        <SelectBox
                                                    label="Branch"
                                                    name={`branch`}
                                                    options={options}
                                                    onChange={(handleChangeBranch)}
                                                    selected={formState[`branch`]}
                                                    defaultValue={{
                                                      label: props?.branch
                                                        ? get(options.filter(c => c.value?.toLowerCase() === props?.branch?.toLowerCase()), '0.label')
                                                        : "Chippenham",
                                                      value: props?.branch ? props?.branch : "chippenham",
                                                    }}
                                                 
                                                />
        </Col>

    
    <Col md={6}>

    <SelectBox
            label="Department"
            name={`department`}
            options={[
                { label: 'Sales', value: 'Sales' },
                { label: 'Rent', value: 'Rent' }]}
                onChange={(handleChangeDep)}
            selected={formState[`department`]}
            defaultValue={ { label: 'Sales', value: 'Sales' }}
        />
</Col>
<Col md={12}>
<TextField
                                                label="Post Code"
                                                name={`postcode`}
                                                placeholder=""
                                                onChange={handleChange}
                                                value={formState[`postcode`]}
                                                className={errClass(`postcode`)}
                                            />
                      {/* <PostcodeField
                        name="postcode"
                        label="Post Code"
                        type="text"
                        // value={formState?.postcode}
                        placeholder="Enter your postcode"
                        postcodeChange={postcodeChange}
                        selectHandelChange={selectHandelChange}
                        className={`mb-24 ${errClass('postcode')} ${errClass('address')}`}

                      /> */}
                      </Col>
                                            </Row>
                                            <TextArea
                                            label="Message"
                                            rows={5}
                                            name="message"
                                            placeholder=""
                                            onChange={handleChange}
                                            className="textarea"
                                            value={formState[`message`]}
                                        />
                                        </div>
                                        
                                        <Recaptcha recaptchaRef={recaptchaRef} />
                                        <div className="form__footer">
                                        <button type="submit"
                                            disabled={btnProcess}
                                            onClick={(event) => { handleSubmit(event) }}
                                            className='btn btn--yellow fz14 fw-bolder'
                                        >Submit Details</button>
                                        <p>
                                            By clicking Submit, you agree to our <Link to="/terms-and-conditions/">Terms & Conditions</Link> and <Link to="/privacy-policy/">Privacy Policy</Link>.
                                            </p>
                                        </div>
                                  
                            </Col>
            </Row>
          </div>

      
        </div>

        {/* <div className="form__footer">
          <button className="btn btn--yellow fz14 fw-bolder">
            Submit Details
          </button>

          <p>
            By clicking Submit, you agree to our
            <Link to="/" className="ms-1 black-link-hover">Terms & Conditions </Link> and
            <Link to="/" className="ms-1 black-link-hover">Privacy Policy</Link>
          </p>
        </div> */}
    </div>
    </section>
  )
}

export default SellLetForm
