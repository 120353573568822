/**
 * External dependencies
 */
import React from "react"
import { Row, Col, Container } from "react-bootstrap"
/**
 * Internal dependencies
 */
import useHomeNews from "./use-home-news"
import { convertGatsbyImageNodes } from "../../utils/gatsby-images"
import LinkIcon from "../link-icon/link-icon"
import newsData from "./static/news"

import "./styles/_index.scss"
import NewsCard from "../card/news-card"
import { useStaticQuery, graphql } from "gatsby";
import {
  NEWS_PAGE_URL
} from "../../components/common/site/constants"
const HomeNews = (props) => {
  // const data = useHomeNews()
  // const images = convertGatsbyImageNodes(data)
  const data = useStaticQuery(graphql`
  query{
    glstrapi {
      blogs(sort: "Date:DESC", publicationState: LIVE, where: {Publish: true}) {
        id
        imagetransforms
        Categories {
          Name
        }
        Date
        Title
        URL
        Tail_Image {
          alternativeText
          url
        }
        Video_Url
      }

    }
  }
  `);
  let processedImages1 = JSON.stringify({})
  if (data?.glstrapi?.blogs[0]?.imagetransforms?.Tail_Image_Transforms) {
    processedImages1 = data?.glstrapi?.blogs[0]?.imagetransforms.Tail_Image_Transforms
  }
  return (
    <div className="home-news pt-24 pb-24 pt-48 pb-48 pt-120 pb-120">
      <Container>
      {props.Cap_Title && <h6 className="home-news__heading fw-bolder fz14">{props.Cap_Title}</h6>}

        <Row className="home-news__head-row g-0 align-items-md-center align-items-lg-start">
          <Col>
          <h3>{props.Title}</h3>
          </Col>
{props?.Cta_Link?.Label && props?.Cta_Link?.Url?.Alias ?
          <Col xs={12} md="auto">
            <LinkIcon href={props?.Cta_Link?.Url?.Alias} text={props?.Cta_Link?.Label} />
          </Col>
          :
          null}
        </Row>

        <Row className="g-0 justify-content-between">
          <Col lg="auto" className="home-news__main-news">
            <NewsCard
                            id={data?.glstrapi?.blogs[0]?.id}
                            imagetransforms={processedImages1}            
              image={data?.glstrapi?.blogs[0]?.Tail_Image}
              video={data?.glstrapi?.blogs[0]?.Video_Url}
              title={data?.glstrapi?.blogs[0]?.Title}
              date={data?.glstrapi?.blogs[0]?.Date}
              category={data?.glstrapi?.blogs[0]?.Categories}
              imgname={"blog.Tail_Image.homebig"}
              href={`${NEWS_PAGE_URL?.alias}/${data?.glstrapi?.blogs[0]?.URL}/`}
              onImageHoverZoom
              alt={data?.glstrapi?.blogs[0]?.Title?data?.glstrapi?.blogs[0]?.Title+" - Strakers Estate Agents":"News - Strakers Estate Agents"}
            />
          </Col>

          <Col lg="auto" className="d-none d-lg-block home-news__aside">
            {data?.glstrapi?.blogs?.slice(1, 4)?.map(x => 
            {
              let processedImages = JSON.stringify({})
        if (x.imagetransforms?.Tail_Image_Transforms) {
          processedImages = x.imagetransforms.Tail_Image_Transforms
        }
              return(
              <NewsCard
                key={x.Title}
                id={x.id}
                imgname={"blog.Tail_Image.homelist"}
                imagetransforms={processedImages}
                image={x.Tail_Image}
                video={x.Video_Url}
                title={x.Title}
                date={x.Date}
                category={x.Categories}
                href={`${NEWS_PAGE_URL?.alias}/${x.URL}/`}
                isRow={true}
                onImageHoverZoom
                alt={x.Title+" - Strakers Estate Agents"}

              />
            )})}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default HomeNews
