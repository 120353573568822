/**
 * External dependencies.
 */
import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import classNames from "classnames"
import { Container } from "react-bootstrap"

/**
 * Internal dependencies.
 */
import "./styles/_index.scss"
import VideoPlaceholder from "../video-placeholder/video-placeholder"
const Bg = ({ image, children, className,
  alt,
  imagetransforms,
  id,
  Pagename,
  imgname }) => (
  <div className={classNames("bg", className)}>
    <div className="bg__image position-absolute w-100 h-100 top-0 start-0 end-0 bottom-0">
    <div className="gatsby-image-wrapper">
      {/* <GatsbyImage image={image} alt="background image" /> */}
      <VideoPlaceholder
          // bg={true}
        playBtnOnRight={false}
          image={image}
          alt={image?.alternativeText}
          imagetransforms={imagetransforms}
          id={id}
          disableLazyLoadOnImage={true}
          Pagename={Pagename}
          imgname={imgname}
        />
        </div>
    </div>
    <Container className="valuation-bk">{children}</Container>
  </div>
)

const BgHead = ({ children, className }) => (
  <div className={classNames("bg__head", className)}>{children}</div>
)
const BgContent = ({ children, className }) => (
  <div className={classNames("bg__inner", className)}>{children}</div>
)

Bg.Head = BgHead
Bg.Content = BgContent

export default Bg
