/**
 * External dependencies
 */
import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import classNames from "classnames"

/**
 * Internal dependencies
 */
import auctions from "./static/auctions"
import CardAuction from "./card-auction"
import useNewsAuctions from "./use-news-auctions"
import { convertGatsbyImageNodes } from "../../utils/gatsby-images"
import Button from "../button/button"
import "./styles/_index.scss"
import { useStaticQuery, graphql } from "gatsby";
import {
  OFFICE_PAGE_URL
} from "../../components/common/site/constants"
import { toCapitalize } from "@components/common/utils";
const NewsAuctions = () => {
  const data = useStaticQuery(graphql`
  query{
    glstrapi {
      offices(sort:"Sort:ASC", publicationState: LIVE, where: {Publish: true}) {
        URL
        id
        Office_Address
        Office_Email
        Office_Name
        Office_Telephone_Lettings
        Office_Telephone_Progression
        Office_Telephone_Sales
        imagetransforms
        Tail_Image {
          alternativeText
          url
        }


        }
    }
  }
  `);
  // const images = convertGatsbyImageNodes(data)

  return (
    <Container className="news-auctions">
      <Row className="news-auctions__row flex-column flex-md-row">
        {data?.glstrapi?.offices?.map((x, index) => {
            let processedImages = JSON.stringify({})
            if (x.imagetransforms?.Tail_Image_Transforms) {
              processedImages = x.imagetransforms.Tail_Image_Transforms
            }
          
          return(
          <Col
            key={x.title}
            className={classNames("news-auctions__col", {
              // "d-none d-lg-block": index >= 2,
            })}
          >
            <CardAuction
                        imagetransforms={processedImages}
                        id={x.id}
            
              image={x.Tail_Image}
              title={toCapitalize(x.Office_Name)}
              description={x.Office_Address}
              sales={x.Office_Telephone_Sales}
              lettings={x.Office_Telephone_Lettings}
              progression={x.Office_Telephone_Progression}
              href={OFFICE_PAGE_URL.alias+'/'+x.URL+'/'}
              agent={x}
              index={index}
              imgname={"office.Tail_Image.list"}
            />
          </Col>
        )})}
      </Row>


    </Container>
  )
}

export default NewsAuctions
