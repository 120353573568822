/**
 * External dependencies
 */
import { useStaticQuery, graphql } from "gatsby";

const useVideoBanner = () => {
  const {
    allFile: { nodes: items },
  } = useStaticQuery(graphql`
     query VideoBannerIntro {
       allFile(
         filter: { relativeDirectory: { eq: "area-guide-intro/images" } }
       ) {
         nodes {
           relativePath
           childImageSharp {
             gatsbyImageData(layout: FULL_WIDTH)
           }
         }
       }
     }
   `)

  return items
}

export default useVideoBanner
