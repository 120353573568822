/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */
import Bg from '../bg/bg';
import RoundedBox from '../rounded-box/rounded-box';
import ReviewsRow from '../reviews-row/reviews-row';
import ValuationForm from '../../blocks/valuation-form/valuation-form';
import useValuationLandingIntro from './use-valuation-landing-intro';
import TrustpilotReviews from "@components/trustpilot"
import './styles/_index.scss';
import { Link } from 'gatsby';
import ContentBlock from "../content/Content";
import HomeVisitValuation from '../../Forms/HomeVisitValuation';
const ValuationLandingIntro = (props) => {
  // const data = useValuationLandingIntro();
  let processedImages = JSON.stringify({})
  if (props?.imagetransforms?.Banner_Image_Transforms) {
    processedImages = props.imagetransforms.Banner_Image_Transforms
  }
  return (
    <Bg
      className="valuation-landing-intro"
      // image={data.childImageSharp.gatsbyImageData}
      image={props.Banner_Image}
      alt={props.Banner_Image?.alternativeText}
      imagetransforms={processedImages}
      id={props.pageid}
      Pagename={props.Pagename}
      imgname={props.imgname}
    >
      <Bg.Head>
      <h1>{props?.Title}</h1>
        <p className="body-xl">
        <ContentBlock Content={props.Content} />
        </p>
      </Bg.Head>
      <Bg.Content>
        <RoundedBox>
          <HomeVisitValuation />
        </RoundedBox>
        {/* <ReviewsRow className="d-none d-md-flex" /> */}
        <TrustpilotReviews
                                language="en"
                                culture="US"
                                theme="dark"
                                width="100%"
                                height="50px"
                                template="5419b6ffb0d04a076446a9af"
                                business="592d87f90000ff0005a381bf"
                                // username="balgoresproperty"
                                data-text-color="#fff"
                                textcolor={'#fff'}
                                stars="4,5"
                            />
      </Bg.Content>
    </Bg>
  )
}

export default ValuationLandingIntro