/**
 * External dependencies
 */
import React, { useEffect, useState } from "react"
import { Col, Container, Form, Row } from "react-bootstrap"
import classNames from "classnames"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { Formik } from 'formik';
import { defaultArea } from "@constants";
/**
 * Internal dependencies
 */
import Button from "../../components/button/button"
import SearchResultInputBox from "../../components/predictive-search/SearchResultBox"
import $ from "jquery";
import { navigate } from "@reach/router"
import "./styles/_index.scss"

// const schema = yup.object({
//   "buy-rent": yup.string().required("Required"),
//   street: yup.string().required("Required"),
// })

const BannerSearchForm = (props) => {
  const [areaVal, setAreaVal] = useState('');
  const [type, setType] = useState("sale");
  const [isSelected, setSelected] = useState(false);
  const pageurl = typeof window !== 'undefined' ? window.location.href : ''
  useEffect(() => {
    if (pageurl?.includes("/rent")) {
      setType("rent")
    }
    else {
      setType("sale")
    }
  }, []);
  // useEffect(() => {
  const onSubmit = () => {
    let url;

    if (areaVal) {
      url = "in-" + areaVal?.replace(/ /g, "-").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').toLowerCase() + "/"
    } 
    // else {
    //   url = ``
    // }

    if (type === 'rent') {
      if (url) {
        navigate('/property/to-rent/' + url);
      }
      else {
        navigate('/property/to-rent/');
      }

    }

    else {
      if (url) {
        navigate('/property/for-sale/' + url);
      }
      else {
        navigate('/property/for-sale/');
      }
    }

  }
  // },[]);
  // const [showSearch, setShowSearch] = useState(false)
  // const {
  //   control,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm({
  //   resolver: yupResolver(schema),
  //   defaultValues: {
  //     "buy-rent": "buy",
  //     street: "",
  //   },
  // })
  // const onSubmit = data => console.log(data)

  // const handleError = name =>
  //   errors[name] && (
  //     <div className="error mt-2">
  //       <small className="text-danger">{errors[name].message}</small>
  //     </div>
  //   )

  // const toggleShow = e => {
  //   e.preventDefault()
  //   setShowSearch(!showSearch)
  // }

  return (
    <div className="banner-form home-search-form">
      <Formik
        initialValues={{ search: '' }}
        validate={values => {
          const errors = {};
          if (!values.search) {
            errors.search = 'Required';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Row className="g-0">
              <Col
                xs="12"
                md="auto"
                className={classNames("pe-md-1_2 mb-lg-0", {
                  // "d-none d-md-block": !showSearch,
                })}
              >
                {/* {type} */}
                <Form.Group className="radio-row">
                  <Row className="g-0">
                    <Col>
                      <input
                        type="radio"
                        name="buy-rent"
                        value="buy"
                        // onChange={field.onChange}
                        onClick={(e) => setType("sale")}
                        checked={type === "sale"}
                        id="radio-buy"
                      />
                      <label htmlFor="radio-buy">Buy</label>
                    </Col>
                    <Col>
                      <input
                        type="radio"
                        name="buy-rent"
                        value="rent"
                        // onChange={field.onChange}
                        onClick={(e) => setType("rent")}
                        checked={type === "rent"}
                        // checked={field.value === "rent"}
                        id="radio-rent"
                      />
                      <label htmlFor="radio-rent">Rent</label>
                    </Col>
                  </Row>
                  {/* {handleError("buy-rent")} */}
                </Form.Group>

              </Col>

              <Col
                xs="12"
                className={classNames("mb-0_8 d-md-none", {
                  // "d-none d-md-block": showSearch,
                })}
              >
                {/* <Button 
              // onClick={toggleShow}
               displayAsLink={false}>
                <Button.Icon>
                  <i className="icon icon--search"></i>
                </Button.Icon>
                <Button.Text>Find a Property</Button.Text>
              </Button> */}
              </Col>

              <Col
                xs="12"
                md="auto"
                className={classNames(
                  "ps-md-1_2 pe-md-0 pe-lg-1_2 mb-lg-0 pt-0_8 pb-0_8 pt-md-0 pb-md-0 flex-grow-1",
                  {
                    // "d-none d-md-block": !showSearch,
                  }
                )}
              >
                <Row>
                  <Col className="street-col pe-lg-1_2">
                    {/* <Controller
                    control={control}
                    name="street"
                    render={({ field }) => ( */}
                    <div className="street-field">
                      <Form.Group>
                        {/* <Form.Control
                            type="text"
                            placeholder="Street, area or postcode"
                            // onChange={field.onChange}
                          /> */}
                        <SearchResultInputBox
                          value={areaVal?.replace(/ /g, '-') !== defaultArea.slug ? areaVal : ''}
                          setValue={setAreaVal}
                          placeHolder={`Street, area or postcode`}
                        // areaList={areaList}
                        // setList={setAreaList}
                        isSelected={isSelected}
                        setSelected={setSelected}
                        />
                      </Form.Group>
                      {/* {handleError("street")} */}
                    </div>
                    {/* )}
                  /> */}
                  </Col>
                  <Col xs="auto" className="col-search ps-md-1_2">
                    <Button displayAsLink={false} onClick={onSubmit} showTextOnMobileOnly={true} className="btn-width sales_btn btn btn--yellow body-m">
                      <Button.Icon className="me-0">
                        <i className="icon icon--search"></i>
                      </Button.Icon>
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default BannerSearchForm
