import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import PageModules from "../components/modules"
import {
  TEAM_PAGE_URL, CAREERS_PAGE_URL, GUIDE_PAGE_URL, OFFICE_PAGE_URL, NEWS_PAGE_URL, VLOGS_PAGE_URL
} from "../components/common/site/constants"
import Layout from "../components/layout"
import { useMatch } from "@reach/router"
import TeamDetails from "../templates/team-details-template"
import AreaGuidesDetail from "../templates/area-guide-template"
import CareerDetail from "./career-details-template"
import OfficeDetail from "./office-details-template"
import BlogDetail from "./blog-details-template"
import VlogDetail from "./vlog-details-template"
import { toCapitalize } from "@components/common/utils";
const DefaultTemplate = props => {
  useEffect(() => {
  document.addEventListener('DOMContentLoaded', function(){
    var elem = document.getElementById("eig-online");
    elem.remove();
});
if(typeof window !== "undefined"&&window){
  var s = document?.getElementById("eig-online-launcher-container")
  var t = document?.getElementById("eig-online-widget-container")
  
  if(s){
    s.style.display = 'none'
  }
  if(t){
    t.style.display = 'none'
  }
}
}, [])



  const Modules = props.data.glstrapi?.page?.Modules
  const Page = props.data.glstrapi?.page
  const Alias = props?.pageContext?.Alias
  const teamDetails = useMatch(TEAM_PAGE_URL.alias + "/:item/")
  const blogDetails = useMatch(NEWS_PAGE_URL.alias + "/:item/")
  const vlogDetails = useMatch(VLOGS_PAGE_URL.alias + "/:item/")
  const officeDetails = useMatch(OFFICE_PAGE_URL.alias + "/:item/")
  const careerDetails = useMatch(CAREERS_PAGE_URL.alias + "/:item/")
  const areaDetails = useMatch(GUIDE_PAGE_URL.alias + "/:item/")
  if (teamDetails !== null) {
    var URL = teamDetails.item
    var page_url = TEAM_PAGE_URL.alias.split("/")
    return (
      <>
        <TeamDetails slug={URL} alias={page_url.pop()} />
      </>
    )
  }
  if (blogDetails !== null) {
    var URL = blogDetails.item
    var page_url = NEWS_PAGE_URL.alias.split("/")
    return (
      <>
        <BlogDetail slug={URL} alias={page_url.pop()} />
      </>
    )
  }
  if (vlogDetails !== null) {
    var URL = vlogDetails.item
    var page_url = VLOGS_PAGE_URL.alias.split("/")
    return (
      <>
        <VlogDetail slug={URL} alias={page_url.pop()} />
      </>
    )
  }
  if (officeDetails !== null) {
    var URL = officeDetails.item
    var page_url = OFFICE_PAGE_URL.alias.split("/")
    return (
      <>
        <OfficeDetail slug={URL} alias={page_url.pop()} />
      </>
    )
  }
  if (careerDetails !== null) {
    var URL = careerDetails.item
    var page_url = CAREERS_PAGE_URL.alias.split("/")
    return (
      <>
        <CareerDetail slug={URL} alias={page_url.pop()} />
      </>
    )
  }
  else if (areaDetails !== null) {
    var URL = areaDetails.item
    var page_url = GUIDE_PAGE_URL.alias.split("/")
    return (
      <>
        <AreaGuidesDetail slug={URL} alias={page_url.pop()} />
      </>
    )
  }
  else {
      return (
        <Layout 
        propertyClass={Page?.Banner_Type === "No_Banner" ? "sticky-page-fix" : ""}
        Alias={Alias}
        isTspPage={Page?.Banner_Type === "Valuation" ? false : Page?.Banner_Image && Page?.Pagename !== "Home" ? true : false}
        Popular_Search_Type={Page?.Popular_Search_Type}
        >
        <Seo
          title={Page ? toCapitalize(Page?.Meta_Title) : ""}
          description={Page ? Page.Meta_Description : ""}
          location={props.location}
        />
        <Helmet
          bodyAttributes={{
            class: `templates-default-template alias-${
              Page ? Page?.Alias : ""
            } homepage ${Page ? Page?.Custom_CSS_Class : ""}`,
          }}
        />
         {Page ? (
          <PageModules
            Modules={Modules}
            Page={Page}
          />
        ) : null}
      </Layout>
    )
         }  
}

export default DefaultTemplate

export const pageQuery = graphql`
  query DeafultQuery($id: ID!) {
    glstrapi {
      page(id: $id, publicationState: LIVE) {
        id
        imagetransforms
      Banner_Content
      Alias
      Banner_Title
      Banner_Type
      Banner_Video
      Custom_CSS_Class
      Show_Banner_Form
      Layout
      Meta_Description
      Meta_Title
      Videoask
      Pagename
      Publish
      Show_Form
      Show_Review
      Social_Share
      Popular_Search_Type
      Background_Video_Desktop {
        url
      }
      Background_Video_Mobile {
        url
      }
      Background_Video_Desktop2 {
        url
      }
      Background_Video_Mobile2 {
        url
      }

      Cta_Links {
        Label
        Link
        Icon_Name
        Link_Type
        Email
        Url {
          Alias
          Label
        }
      }
      Banner_Image {
        alternativeText
        url
      }
      Modules {
        ... on GLSTRAPI_ComponentComponentAddValuation {
          id
          __typename
          Type
          Title
          Content
        }
        ... on GLSTRAPI_ComponentComponentTermsBlock {
          id
          Content
          Add_Content
          __typename
          _id
          Toggle_Block {
            Content
            Title
          }
          Right_Sidebar_top {
            Title
            Cta_Buttons {
              Icon_Name
              Label
              Link
              Link_Type
              Url {
                Alias
              }
            }
          }
          Right_Sidebar_Bottom {
            Content
            Title
            Cta_Btn {
              Icon_Name
              Label
              Link
              Link_Type
              Url {
                Alias
              }
            }
          }
        }
        ... on GLSTRAPI_ComponentComponentStaffVideos {
          id
          __typename
          ...StaffVideosFragment
        }
        ... on GLSTRAPI_ComponentComponentGetStarted {
          id
          __typename
          ...GetStartedFragment
        }
        ... on GLSTRAPI_ComponentComponentBannerCtaBlock {
          id
          __typename
          ...BannerCtaBlockFragment
        }
        ... on GLSTRAPI_ComponentComponentStatisticsBlock {
          id
          __typename
          ...StatisticsBlockFragment
          
        }
        ... on GLSTRAPI_ComponentComponentSoldModule {
          id
          __typename
          Title
          Big_Title
          Add_Item {
            Title
            Price
            Image {
              alternativeText
              url
            }
          }
        }
        ... on GLSTRAPI_ComponentComponentAboutContent {
          __typename
          ...AboutContentFragment
        }
        ... on GLSTRAPI_ComponentComponentStepper {
          id
        Add_Step {
          Content
          Title
          Image {
            alternativeText
            url
            url_sharp {
              childImageSharp {
                gatsbyImageData(
                  formats: WEBP
                  width: 900
                  quality: 100
                  layout: CONSTRAINED
                  transformOptions: { cropFocus: CENTER, fit: COVER }
                ) 
              }
            }
          }
          CTA_LInk {
            Label
            Url {
              Alias
            }
          }
        }
          __typename
        }
        ... on GLSTRAPI_ComponentComponentAddContent {
          id
          __typename
          Content
          Add_Image {
            Image {
              alternativeText
              url
            }
          }
        }
        ... on GLSTRAPI_ComponentComponentServicesCta {
          __typename
          id
          Top_On
          Cap_Title
          Title
          Add_Services_Cta {
            Title
            Services_Cta_Link {
              Label
              Link
              Link_Type
              Url {
                Alias
              }
            }
            Image {
              alternativeText
              url
              url_sharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    width: 900
                    quality: 100
                    layout: CONSTRAINED
                    transformOptions: { cropFocus: CENTER, fit: COVER }
                  ) 
                }
              }
            }
          }
  

        }
        ... on GLSTRAPI_ComponentComponentCustomerReviews {
          id
          __typename
          ...CustomerReviewsFragment

        }
        ... on GLSTRAPI_ComponentComponentAwards {
          id
          __typename
          ...ComponentAwardsFragment
        }
        ... on GLSTRAPI_ComponentComponentCollections {
          id
          __typename
          Content
          Select_Collection
          Title
        }
        ... on GLSTRAPI_ComponentComponentModules {
          id
          __typename
          Cap_Title
          Select_Module
          Title
          Cta_Link {
            Label
            Link
            Url {
              Alias
            }
          }
        }
       

      }
    
    
    
    }
    }
  }
`
