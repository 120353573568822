/**
 * External dependencies
 */
import React from "react"
import { Col, Row } from "react-bootstrap"
import { Link } from "gatsby"
/**
 * Internal dependencies
 */
import LinkIcon from '../link-icon/link-icon'
import "./style/_index.scss"
import classNames from "classnames"
const Position = ({ children, title, link }) => (
  <Row className="position align-items-start justify-content-between justify-content-lg-start mb-1_6 shadow-sm g-0">
    <Col className="position__title body-l mb-0" xs="6" lg="auto">
      <h5 className="d-inline">{title}</h5>
    </Col>
    {children}
    <Col xs="auto" className="position__link">
      {/* <LinkIcon
        href={link}
        text='Details'
      /> */}
        <Link               class="link-icon blue-link-hover d-flex align-items-center"
              
                            to={link}
                          >
                             <div
      className={`link-icon__text fw-bolder fz14 position-relative order-0`}
    >
      Details
    </div>
    <i className={classNames(`icon icon--arrow-up-right`)}></i>
                          </Link>
    </Col>
  </Row>
)

export default Position
