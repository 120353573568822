/**
 * External dependencies
 */
 import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap"
import classNames from "classnames"

/**
 * Internal dependencies
 */
import sales from "./static/sales"
import CardSale from "./card-sale"
import useNewsSales from "./use-news-sales"
import Button from "../button/button"
import { convertGatsbyImageNodes } from "../../utils/gatsby-images"
import "./styles/_index.scss"
import {
  TEAM_PAGE_URL
} from "../../components/common/site/constants"
import { useStaticQuery, graphql } from "gatsby";
const NewsSales = () => {
  const data = useStaticQuery(graphql`
    query{
      glstrapi {
        teams(sort:"Sort:ASC", publicationState: LIVE, where: {Publish: true}) {
            id
            imagetransforms
            Name
            Email
            Designation
            Video_Url
            URL
            Image {
              alternativeText
              url
            }
          }
      }
    }
    `);
    const [teams, setTeams] = useState(data.glstrapi?.teams)

  // const data = useNewsSales()
  // const images = convertGatsbyImageNodes(data)
  const postsPerPage = 12;
	let arrayForHoldingPosts = [];
	// const [] = useState(0);
	const [postsToShow, setPostsToShow] = useState([]);
	const [next, setNext] = useState(12);
	const loopWithSlice = (start, end) => {
        const slicedPosts = teams?.slice(0, end);
        if(teams){
            arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
            setPostsToShow(arrayForHoldingPosts);
        }

	};
  const handleShowMorePosts = () => {
		loopWithSlice(next, next + postsPerPage);
		setNext(next + postsPerPage);
    sessionStorage.setItem("teampage", next + postsPerPage)
	};

	useEffect(() => {
    if(sessionStorage.getItem('teampage') != null){
      
      var idelement = sessionStorage.getItem('teampage');
      
         setTimeout(function() { 
          loopWithSlice(0, parseInt(idelement));
          setNext(parseInt(idelement))  
          if(sessionStorage.getItem('teamid') != null){
            var elmntidelement = sessionStorage.getItem('teamid');
            var elmnt = document?.getElementById(elmntidelement.toString());
            if(elmnt){
            elmnt?.scrollIntoView();      
            sessionStorage.removeItem('teamid')
            // sessionStorage.removeItem('teampage')
          }
          else {
            // window?.scroll(0, 0)
          }
           }
       }, 1000);  
     }
     else {
      loopWithSlice(0, postsPerPage);
      setNext(12)  
     }

	}, []);
  return (
    <Container className="news-sales">
      <Row className="news-sales__row flex-column flex-md-row">
        {postsToShow?.map((x, index) => {
           let processedImages = JSON.stringify({})
           if (x.imagetransforms?.Image_Transforms) {
             processedImages = x.imagetransforms.Image_Transforms
           }
          return(
          <Col
            key={index}
            id={x.Name?.replace(/ /g, '_')}
            className={classNames("news-sales__col")}
          >
            <CardSale
            imagetransforms={processedImages}
            id={x.id}
              image={x.Image}
              video={x.Video_Url}
              name={x.Name}
              position={x.Designation}
              href={TEAM_PAGE_URL.alias+'/'+x.URL+'/'}
              index={index}
              agent={x}
              imgname={"team.Image.list"}
            />
          </Col>
        )})}
{postsToShow?.length > 0 && teams && postsToShow?.length !== teams?.length &&
  <Col className="load-more-bt">
<Button
        className="mt-3_2 mt-lg-6 btn--outline"
        color="tsp"
        borderColor="blue"
        textColor=""
        displayAsLink={false}
        onClick={handleShowMorePosts}
      >
        <Button.Text>Load more</Button.Text>
      </Button>
      </Col>
      }
      </Row>
    </Container>
  )
}

export default NewsSales
