const galleryData = [
  {
    image: 2,
    title: `What's my property worth?`,
    linkText: 'Sell or Let your home',
    linkHref: '/'
  },
  {
    image: 1,
    title: 'Calling all Landlords',
    linkText: 'Manage my property',
    linkHref: '/'
  },
  {
    image: 3,
    title: 'Do you cover my area?',
    linkText: 'Areas we cover',
    linkHref: '/'
  },
  {
    image: 4,
    title: 'Is my property suitable for auction?',
    linkText: 'Explore Auctions',
    linkHref: '/'
  },
  {
    image: 5,
    title: 'New Homes in Wiltshire',
    linkText: 'Explore New Homes',
    linkHref: '/'
  },
];

const galleryColSize2 = {
  0: 'col-md-6 col-lg-6',
  1: 'col-md-6 col-lg-6'
};

const galleryColSize3 = {
  0: 'col-md-6 col-lg-4',
  1: 'col-md-6 col-lg-4',
  2: 'col-md-6 col-lg-4'
};

const galleryColSize4 = {
  0: 'col-md-6 col-lg-6',
  1: 'col-md-6 col-lg-6',
  2: 'col-md-6 col-lg-6',
  3: 'col-md-12 col-lg-6',
};

const galleryColSize5 = {
  0: 'col-md-6 col-lg-4',
  1: 'col-md-6 col-lg-4',
  2: 'col-md-6 col-lg-4',
  3: 'col-md-6 col-lg-6',
  4: 'col-md-12 col-lg-6',
};

const galleryColSize5top = {
  0: 'col-md-6 col-lg-6',
  1: 'col-md-6 col-lg-6',
  2: 'col-md-6 col-lg-4',
  3: 'col-md-6 col-lg-4',
  4: 'col-md-12 col-lg-4',
};

const galleryColSize6 = {
  0: 'col-md-6 col-lg-4',
  1: 'col-md-6 col-lg-4',
  2: 'col-md-6 col-lg-4',
  3: 'col-md-6 col-lg-4',
  4: 'col-md-6 col-lg-4',
  5: 'col-md-6 col-lg-4',
};

const galleryCardClassNames = {
  2: 'text-space',
  3: 'text-space',
  4: 'text-space',
}

export {
  galleryData,
  galleryCardClassNames,
  galleryColSize2,
  galleryColSize3,
  galleryColSize4,
  galleryColSize5,
  galleryColSize5top,
  galleryColSize6
}