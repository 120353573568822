/**
 * External dependencies
 */
import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import LinkIcon from "../link-icon/link-icon"
import { StaticImage } from "gatsby-plugin-image"
import ContentBlock from "../content/Content";
import { graphql } from "gatsby"
/**
 * Internal dependencies
 */
import BulletInfo from "../bullet-info/bullet-info"
import bulletData from "./static/bullet-data"
import "./styles/_index.scss"
import { inViewOptions, containerVariants, contentItem, fadeInFromTop } from '../common/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
const MotionColumn = motion(Col)
const Statistics = ({Statistic_data}) => (
    <InView  {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          ref={ref}
          className="home-info statastics pt-40 pb-40"
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}

        >
    <Container>
      <Row className="home-info__bullets">
        {Statistic_data?.Add_Statistic?.map((bullet, index) => (
          <MotionColumn className="col-bullet" key={index} lg variants={contentItem}>
            <BulletInfo
              icon={bullet.Icon}
              title={bullet.Percentage}
              description={bullet.Content}
              Prefix={bullet?.Prefix}
              Suffix={bullet?.suffix}
              inView={inView}
            />
          </MotionColumn>
        ))}
      </Row>
    </Container>
  
</motion.div>
      )}
    </InView>
)

export default Statistics
export const statisticsBlockFragment = graphql`
    fragment StatisticsBlockFragment on GLSTRAPI_ComponentComponentStatisticsBlock {
        id
        Statistic_data {
          Publish
          Title
          Add_Statistic {
            Content
            Percentage
            Prefix
            suffix
            Icon {
              alternativeText
              url
            }
          }
        }
    }
`