/**
 * External dependencies
 */
import React from "react"
import { Row, Col } from "react-bootstrap"

/**
 * Internal dependencies
 */
import Bg from "../bg/bg"
import RoundedBox from "../rounded-box/rounded-box"
import EstimateValue from "../estimate-value/estimate-value"
import ReviewsRow from "../reviews-row/reviews-row"
import Button from "../button/button"
import TrustpilotReviews from "@components/trustpilot"
import "./styles/_index.scss"
import ContentBlock from "../content/Content";
import InstantValuationForm from "../../Forms/instant-valuation-form"
// import InstantValuationForm from "../../Forms/~InstantValuation"
const InstantValuationIntro = (props) => {
  let processedImages = JSON.stringify({})
  if (props?.imagetransforms?.Banner_Image_Transforms) {
    processedImages = props.imagetransforms.Banner_Image_Transforms
  }
  return (
    <Bg
      className="instant-valuation-intro"
      // image={data.childImageSharp.gatsbyImageData}
      image={props.Banner_Image}
      alt={props.Banner_Image?.alternativeText}
      imagetransforms={processedImages}
      id={props.pageid}
      Pagename={props.Pagename}
      imgname={props.imgname}
    >
      <Bg.Head className="d-none d-md-block">
      <h1>{props?.Title}</h1>
        <p className="body-xl">
        <ContentBlock Content={props.Content} />
        </p>
      </Bg.Head>
      <Bg.Content>
        <RoundedBox>
        {/* <InstantValuationForm /> */}
        <InstantValuationForm />
        </RoundedBox>
        {/* <ReviewsRow /> */}
        <TrustpilotReviews
                                language="en"
                                culture="US"
                                theme="dark"
                                width="100%"
                                height="50px"
                                template="5419b6ffb0d04a076446a9af"
                                business="592d87f90000ff0005a381bf"
                                // username="balgoresproperty"
                                data-text-color="#fff"
                                textcolor={'#fff'}
                                stars="4,5"
                            />

      </Bg.Content>
    </Bg>
  )
}

export default InstantValuationIntro
