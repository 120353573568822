/**
 * External dependencies
 */
import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"
import GetURL from "../../components/common/site/get-url"
/**
 * Internal dependencies
 */
import "./styles/gallery-card/_index.scss"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
const GalleryCard = ({
  className,
  image,
  alt,
  title,
  linkText,
  linkHref,
  children,
  link,
  onImageHoverZoom,
  linktype,
  showForm,
  openform
}) => {
  const classes = classNames(
    "gallery-card position-relative d-flex flex-column justify-content-end",
    className
  )
  const innerChilds = (
    <>
      
    </>
  )
  if (link) {
    
  
    return (
      linktype === "SubscribeForm" ?
      <a onClick={() => openform(true)}
                href="javascript:void(0)"
                className={classes}
                >
                  <div className={classNames(`gallery-card__image position-absolute w-100 h-100`, {
        'hover-zoom': onImageHoverZoom
      })}>
        {image && 
        <div className="gatsby-image-wrapper">
        {/* <img src={image?.url} alt={alt} /> */}
        <GatsbyImage image={getImage(image.url_sharp)} alt={alt} />
        </div>
        }
      </div>
      <div className="gallery-card__text position-relative">
        {title && <h5>{title}</h5>}
        {linkText && (
          
          <div className="link-icon d-flex align-items-center">
            <div className="link-icon__text fw-bolder fz14 position-relative order-0">
              {linkText}
            </div>

            <i className="icon icon--arrow-up-right"></i>
          </div>
          
        )}

        {children}
      </div>
      
                </a>
      :
      <GetURL
           class={classes}
           label={""}
           alias={linkHref}
         >
          {/* {JSON.stringify(linkHref)} */}
<div className={classNames(`gallery-card__image position-absolute w-100 h-100`, {
        'hover-zoom': onImageHoverZoom
      })}>
        {image && 
        <div className="gatsby-image-wrapper">
        {/* <img src={image?.url} alt={alt} /> */}
        <GatsbyImage image={getImage(image.url_sharp)} alt={alt} />
        </div>
        }
      </div>
      <div className="gallery-card__text position-relative">
        {title && <h5>{title}</h5>}
        {linkText && (
          
          <div className="link-icon d-flex align-items-center">
            <div className="link-icon__text fw-bolder fz14 position-relative order-0">
              {linkText}
            </div>

            <i className="icon icon--arrow-up-right"></i>
          </div>
          
        )}

        {children}
      </div>
      </GetURL>

    
    )
  }

  return <div className={classes}>{innerChilds}</div>
}

export default GalleryCard
