/**
 * External dependencies
 */
import classNames from "classnames"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { Row, Col } from "react-bootstrap"
import moment from "moment"

/**
 * Internal dependencies
 */
import VideoPlaceholder from "../video-placeholder/video-placeholder"
import "./styles/news-card/_index.scss"

const NewsCard = ({
  isRow = false,
  image,
  video,
  title,
  date,
  category,
  href,
  onImageHoverZoom,
  imgname, imagetransforms, id, alt, playBtnOnRight = false
}) => {
  let processedImages = imagetransforms
  // if (imagetransforms?.Tail_Image_Transforms) {
  //   processedImages = imagetransforms.Tail_Image_Transforms
  // }
  return(
  <Row
    className={classNames("news-card g-0", {
      "is-row align-items-center": isRow,
      "flex-column": !isRow,
    })}
  >
    <Col lg="auto" className="news-card__head position-relative">
      <div className={classNames('news-card__head-inner position-absolute h-100 w-100',{
        'hover-zoom': onImageHoverZoom
      })}>
          <VideoPlaceholder 
          image={image}
          imagetransforms={processedImages}
          id={id}
          video={video}
          alt={alt}
          playBtnOnRight={playBtnOnRight}
          imgname={imgname}
          nowrapper={true}
          href={href}
           />
      </div>
    </Col>

    <Col className="news-card__body">
      <h5>
        <Link to={href} className="d-block">
          {title}
        </Link>
      </h5>

      <span className="fw-bolder black">{moment(date).format("ddd DD MMMM YYYY")}</span>
      <span className="delimiter">/</span>
      <span className="fw-bolder black">      {category?.map((list, index) => (
                      <>
                        {list.Name}
                        {index === category?.length - 1 ? "" : ", "}
                      </>
                    ))}
</span>
    </Col>
  </Row>
)}

export default NewsCard
