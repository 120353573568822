/**
 * External dependencies
 */
import { useStaticQuery, graphql } from "gatsby"

const useHomeGallery = () => {
  const {
    allFile: { nodes: items },
  } = useStaticQuery(graphql`
    query HomeGallery {
      allFile(
        filter: { relativeDirectory: { eq: "home-gallery/images/gallery" } }
      ) {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  `)

  return items
}

export default useHomeGallery
