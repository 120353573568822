/**
 * External dependencies
 */
import { Link } from "gatsby"
import React from "react"
import { Row, Col } from "react-bootstrap"
/**
 * Internal dependencies
 */
import Card from "../card/card"
import LinkIcon from "../link-icon/link-icon"
import classNames from "classnames"
const CardLand = ({ imgname, imagetransforms, id, image, video, title, href, designation, isReview }) => (
  <Card className="card-land">
    
    <Card.Head
      image={image}
      video={video}
      playBtnOnRight={true}
      alt={title+" - Strakers Estate Agents"}
      href={href}
      onImageHoverZoom
      imgname={imgname} imagetransforms={imagetransforms} id={id}
    />
    <Card.Body>
      <Row className="g-0 align-items-center">
        <Col>
          <h5>
            <Link to={href}>{title}</Link>
          </h5>
        </Col>
        {isReview ? "" : 
        <Col xs="auto">
          <Link to={href} className="link-icon blue-link-hover d-flex align-items-center">
          <div
      className={`link-icon__text fw-bolder fz14 position-relative order-0`}
    >
      View Guide
    </div>
    <i className={classNames(`icon icon--arrow-up-right`)}></i>
          </Link>
          {/* <LinkIcon text="View Guide" /> */}
        </Col>}
      </Row>
      {isReview ?  
      <p>{designation}</p>
      : ""}
    </Card.Body>
  </Card>
)

export default CardLand
