/**
 * External dependencies.
 */
import React from "react"
import classNames from "classnames"

/**
 * Internal dependencies.
 */
import "./styles/_index.scss"

const EstimateValue = ({ items }) => (
  <div className="estimate-value d-md-flex justify-content-between">
    {items?.map((item, index) => (
      <div
        key={index}
        className={classNames("estimate-value__item", {
          "is-large": item.large,
        })}
      >
        <h6>{item.type}</h6>
        <h5>{item.value}</h5>
      </div>
    ))}
  </div>
)

export default EstimateValue
