import React from 'react';
import { Nav, Tab, Row, Col } from "react-bootstrap"
import { currencyFormat } from "@components/common/utils";
import './resultTabs.scss';
import Button from "../../button/button"
import EstimateValue from "../../estimate-value/estimate-value"
const ResultTabs = ({ resultData = {} }) => { 

    return (
        <div className="result-wrapper">
            <Tab.Container
                defaultActiveKey="first"
                id="result-tabs"
            >
                  <Row className=" g-0">
        
                <Nav className="">
                    <Nav.Item>
                        <Nav.Link eventKey="first">
                            Sales
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="second">
                        Lettings
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                </Row>
                <Tab.Content>
                    <Tab.Pane eventKey="first">
                    <EstimateValue
                        items={[
                        {
                            type: "Min Value",
                            value: currencyFormat(resultData?.minimum_sale_estimation),
                            large: false,
                        },
                        {
                            type: "Est Value",
                            value: currencyFormat(resultData?.average_sale_estimation),
                            large: true,
                        },
                        {
                            type: "Max Value",
                            value: currencyFormat(resultData?.maximum_sale_estimation),
                            large: false,
                        },
                        ]}
                    />
                       
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <EstimateValue
                        items={[
                        {
                            type: "Min Value",
                            value: currencyFormat(resultData?.minimum_rent_estimation),
                            large: false,
                        },
                        {
                            type: "Est Value",
                            value: currencyFormat(resultData?.average_rent_estimation),
                            large: true,
                        },
                        {
                            type: "Max Value",
                            value: currencyFormat(resultData?.maximum_sale_estimation),
                            large: false,
                        },
                        ]}
                    />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </div>
    )
}

export default ResultTabs;

const RangeValue = (props) => {

    const { min, est, max } = props;

    return (
        <div className="range-value">
            <div className="line-dot-wrapper">
                <div className="line" />
                <div className="line-dots">
                    <span className="dot" />
                    <span className="dot est-value" />
                    <span className="dot" />
                </div>
            </div>
            <div className="values-wrapper">
                <div className="range-text align-items-start">
                    <span className="pre-title">
                        Min Value
                    </span>
                    {
                        min && (
                            <span className="value">
                                {currencyFormat(min)}
                            </span>
                        )
                    }
                </div>
                <div className="range-text est-value align-items-center">
                    <span className="pre-title">
                        Est Value
                    </span>
                    {
                        est && (
                            <span className="value">
                                {currencyFormat(est)}
                            </span>
                        )
                    }
                </div>
                <div className="range-text align-items-end">
                    <span className="pre-title">
                        Max Value
                    </span>
                    {
                        max && (
                            <span className="value">
                                {currencyFormat(max)}
                            </span>
                        )
                    }
                </div>
            </div>
        </div>
    )
}