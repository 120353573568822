const bulletData = [
  {
    icon: 'check',
    title: '100%',
    description: 'success rate in selling properties that other agents have not sold.'
  },
  {
    icon: 'chats',
    title: '96%',
    description: 'the percentage of people that would recommend us.'
  },
  {
    icon: 'house-line',
    title: '98%',
    description: 'of offers successfully managed to exchange.'
  }
];

export default bulletData;