import React, { useState, createRef, useEffect } from "react"
import { Row, Col, Button } from "react-bootstrap";
import TextField from "@components/formComponents/textField";
import PostcodeField from "@components/formComponents/postcode";
// import ModuleText from '@components/modules/Text';
import ReactSelect from "@components/formComponents/reactSelect";
import Result from "@components/valuation/Result";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ApiRequest } from "@utils";
import { ScrollToTop, gtmEvent, SendMail, toCapitalize } from "@components/common/utils";
import { postStbFormData, api_form_error } from "./api/Api"
import * as qs from "query-string"
import _ from 'lodash';
import { isEmpty, get, find, includes } from "lodash"
import moment from 'moment';
import SelectBox from "./Inputs/SelectBox"
import { useStaticQuery, graphql } from "gatsby";
// import { getUser } from "@myaccount/website-service";
import "./Form.scss";
const bedOptions = [
  { value: 1, label: '1 bed' },
  { value: 2, label: '2 beds' },
  { value: 3, label: '3 beds' },
  { value: 4, label: '4 beds' },
  { value: 5, label: '5 beds' },
];

const propertyOptions = [
  { value: 'Sell', label: 'Sell my property' },
  { value: 'Let', label: 'Let my property' }
];

const planOptions = [
  { value: 'ASAP', label: 'ASAP' },
  { value: '3 months', label: '3 months' },
  { value: '6 months', label: '6 months' },
  { value: '9 months', label: '9 months' },
  { value: '12 months', label: '12 months' },
  { value: 'Not sure', label: 'Not sure' },
];

const InstantValuationForm = (props) => {
  const data = useStaticQuery(graphql`
  query{
    glstrapi {
      offices(sort:"Sort:ASC", publicationState: LIVE, where: {Publish: true}) {
        Office_Name
        Office_Email
      }
    }
  }
  `);
  const myRef = createRef();
  const formFields = {
    form_name: "leadpro-valuation",
    location: null,
    address: null,
    postcode: null,
    bedrooms: null,
    propertyType: null,
    branch: 'Chippenham',
    // plan: null,
    first_name: null,
    last_name: null,
    email: null,
    telephone: null,
    gdprCheckbox: null,
    host: "strakers.lead.pro",
    publicUuid: null,
    type: "vendor",
  }

  const [step, setStep] = useState(1);
  const [btnProcess, setProcess] = useState(false);
  const [formState, setFormState] = useState(formFields);
  const [bedroomselected, setbedroomselected] = useState({ value: null, label: 'Please select' });
  const [propertySelected, setPropertyTypeselected] = useState({ value: null, label: 'Please select' });
  // const [planSelected, setPlanselected] = useState({ value: null, label: 'Please select' });
  const [errors, setErrors] = useState({});
  const [errorFlag, setErrorFlag] = useState(false);
  const [leadProResult, setLeadProResult] = useState(null);
  const [startDate, setStartDate] = useState(null);

  // const userData = getUser();

  useEffect(() => {
    // Prefill user data if user logined in
    // if (!isEmpty(userData)) {
    //   if (!sessionStorage.getItem('firstName'))
    //     sessionStorage.setItem('firstName', userData?.name || '');
    //   if (!sessionStorage.getItem('lastName'))
    //     sessionStorage.setItem('lastName', userData?.surname || '');
    //   if (!sessionStorage.getItem('email'))
    //     sessionStorage.setItem('email', userData?.email || '');
    //   if (!sessionStorage.getItem('phone'))
    //     sessionStorage.setItem('phone', userData?.mobile || '');
    //   if (!sessionStorage.getItem('address'))
    //     sessionStorage.setItem('address', '');
    //   if (!sessionStorage.getItem('postcode'))
    //     sessionStorage.setItem('postcode', '');
    //   if (!sessionStorage.getItem('bedrooms'))
    //     sessionStorage.setItem('bedrooms', '');
    //   if (!sessionStorage.getItem('propertyType'))
    //     sessionStorage.setItem('propertyType', '');
    //   if (!sessionStorage.getItem('plan'))
    //     sessionStorage.setItem('plan', '');
    //   if (!sessionStorage.getItem('selectedBedrooms'))
    //     sessionStorage.setItem('selectedBedrooms', JSON.stringify({ value: null, label: 'Please select' }));
    //   if (!sessionStorage.getItem('selectedPropertyType'))
    //     sessionStorage.setItem('selectedPropertyType', JSON.stringify({ value: null, label: 'Please select' }));
    //   if (!sessionStorage.getItem('selectedPlan'))
    //     sessionStorage.setItem('selectedPlan', JSON.stringify({ value: null, label: 'Please select' }));
    // }

    let sessionValues = {
      ...formState,
      first_name: sessionStorage.getItem('first_name') != null ? sessionStorage.getItem('first_name') : '',
      last_name: sessionStorage.getItem('last_name') != null ? sessionStorage.getItem('last_name') : '',
      email: sessionStorage.getItem('email') != null ? sessionStorage.getItem('email') : '',
      telephone: sessionStorage.getItem('telephone') != null ? sessionStorage.getItem('telephone') : '',
      address: sessionStorage.getItem('address') != null ? sessionStorage.getItem('address') : '',
      postcode: sessionStorage.getItem('postcode') != null ? sessionStorage.getItem('postcode') : '',
      propertyType: sessionStorage.getItem('propertyType') != null ? sessionStorage.getItem('propertyType') : '',
      // plan: sessionStorage.getItem('plan') != null ? sessionStorage.getItem('plan') : '',
      bedrooms: sessionStorage.getItem('bedrooms') != null ? sessionStorage.getItem('bedrooms') : ''
    };

    setFormState(sessionValues);
    if (sessionStorage.getItem('selectedBedrooms') != null)
      setbedroomselected(JSON.parse(sessionStorage.getItem('selectedBedrooms')));
    if (sessionStorage.getItem('selectedPropertyType') != null)
      setPropertyTypeselected(JSON.parse(sessionStorage.getItem('selectedPropertyType')));
    // if (sessionStorage.getItem('selectedPlan') != null)
    //   setPlanselected(JSON.parse(sessionStorage.getItem('selectedPlan')));
  }, []);

  useEffect(() => {
    sessionStorage.setItem('selectedBedrooms', JSON.stringify(bedroomselected));
  }, [bedroomselected]);

  useEffect(() => {
    sessionStorage.setItem('selectedPropertyType', JSON.stringify(propertySelected));
  }, [propertySelected]);

  // useEffect(() => {
  //   sessionStorage.setItem('selectedPlan', JSON.stringify(planSelected));
  // }, [planSelected]);

  const handleChange = (e) => {
    sessionStorage.setItem(e.target.name, e.target.value);

    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });

  }
  const handleChangeBranch = (e) => {
    setFormState({
        ...formState,
        "branch": e.label,
    });
}
  const postcodeChange = (suggestion) => {
    var selected_address = suggestion.line_1 ? suggestion.line_1+", " : "";
    selected_address += suggestion.line_2 ? suggestion.line_2+", " : "";
    selected_address += suggestion.line_3 ? suggestion.line_3+", " : "";
    selected_address += suggestion.postcode ? suggestion.postcode+", " : "";
    sessionStorage.setItem('address', selected_address);
    sessionStorage.setItem('postcode', suggestion.postcode);

    setFormState({
      ...formState,
      // location: suggestion,
      "address": selected_address,
      "postcode": suggestion.postcode,
    });
  }
  const selectHandelChange = (name, value) => {
    sessionStorage.setItem(name, value);

    setFormState({
      ...formState,
      [name]: value
    });
  }

  const handleValidation = (fields, step) => {
    // debugger
    // console.log(fields)
    var error = {}
    var isValid = true;
    var validationFields = [];
    if (step === 1) {
      validationFields = ['postcode', 'address', 'bedrooms', 'propertyType'];
    } else if (step === 2) {
      validationFields = ['first_name', 'last_name', 'email', 'telephone'];
    }
    _.forEach(validationFields, (field, k) => {
      if (fields[field] === null || fields[field] === "") {
        error[field] = true;
        isValid = false
      } else {
        if (field === "email" && !(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(fields[field]))) {
          error[field] = true;
          isValid = false
        } else if (field === "telephone" && fields[field].length <= 8) {
          error[field] = true;
          isValid = false
        } else {
          error[field] = false;
        }
      }
    })

    if (!isValid) {
      // $('html, body').animate({
      //   scrollTop: $("#jobform").offset().top
      // }, 100);
      setErrors(error)
      return false;
    }


    return true
  }
  const handleSubmit = (event, step) => {
    event.preventDefault();
    if (handleValidation(formState, step)) {
      // console.log(step)
      // debugger
      setErrorFlag(false);
      ScrollToTop(myRef.current, 0)
      if (step === 2) {
        // debugger
        setProcess(true)
        // formSubmission();
        let formData = new FormData();
        formData['name'] = formState.first_name + ' ' + formState.last_name
        formData['first_name'] = formState.first_name
        formData['fname'] = formState.first_name
        formData['lname'] = formState.last_name
        formData['last_name'] = formState.last_name
        formData['post_code'] = formState.postcode
        formData['form_name'] = formState.form_name
        formData['telephone'] = formState.telephone
        formData['email'] = formState.email
        formData['address'] = formState.address

        // console.log(formState)
        // console.log(formData)
        formData.append('data', JSON.stringify(formState));
        // console.log(formData)
        postStbFormData(formData).then(async apiRes => {
    
          //console.log("apiRes", apiRes); 
          // window?.grecaptcha?.reset();
          // console.log(apiRes)
          if (apiRes?.success === true) {
            // tracking event
            // window.dataLayer = window.dataLayer || [];
            // window.dataLayer.push({
            //   'event': 'formSubmit',
            //   'formType': 'form-' + fields[0].event_tracking,
            //   'formId': 'form-' + fields[0].event_tracking,
            //   'formName': fields[0].formname,
            //   'formLabel': fields[0].formname
            // });
    
    
            setProcess(false);
            setStep(step + 1);
            setLeadProResult(apiRes?.leadpro_response?.data)
          // GTM tracking event 
          gtmEvent({ formType: 'Instant online - property valuation', formName: 'Instant online - property valuation' })
            // setTimeout(() => {
            //   setThankyou(false)
            // }, 3000);
    
            // if (apiRes?.leadpro_response?.status) {
            //   sessionStorage.setItem('valuationResult', JSON.stringify(apiRes?.leadpro_response?.data));
            //   navigate('/valuation-result')
            // }
            var postData = _.cloneDeep(formState)
            postData['to_admin'] = data.glstrapi?.offices?.filter(list => list.Office_Name?.includes(formState?.branch?.toLowerCase()))[0]?.Office_Email?.toLowerCase()
            postData['extra'] = formState;
            postData['type'] = formState.propertyType
            postData['address'] = formState.address
            postData['bedrooms'] = formState.bedrooms
            postData['fname'] = formState.first_name
            postData['lname'] = formState.last_name
            // postData['to_bcc'] = "marketing@strakers.co.uk";
            // postData['from_bcc'] = "marketing@strakers.co.uk";
            postData['email_temp_user'] = 'instant_valuation_user';
            postData['email_temp_admin'] = 'instant_valuation_admin';
            postData['email_subject_user'] = 'Strakers Valuation';
            postData['email_subject_admin'] = 'Strakers Valuation';
            sessionStorage.removeItem('first_name')
            sessionStorage.removeItem('last_name')
            sessionStorage.removeItem('email')
            sessionStorage.removeItem('telephone')
            sessionStorage.removeItem('address')
            sessionStorage.removeItem('postcode')
            sessionStorage.removeItem('propertyType')
            sessionStorage.removeItem('bedrooms')
            sessionStorage.removeItem('selectedPropertyType')
            sessionStorage.removeItem('selectedBedrooms')
            await SendMail(postData);
          } else {
            // setErrors(validation.errors)
            // setErrorFlag(true)
            // setShowerrorMsg(api_form_error)
          }
        });

      } else {
        setStep(step + 1)
        setErrors({})
      }

    } else {
      setErrorFlag(true)
      ScrollToTop(myRef.current, 50)
    }
  }
  const formSubmission = () => {
    
   
    var postData = _.cloneDeep(formState)
    postData['extra'] = formState;
    postData.name = postData.first_name + ' ' + postData.last_name;
    postData.telephone = postData.telephone;
    postData.formname = "Instant Online Valuation Request"
    postStbFormData(postData).then(async apiRes => {
      setProcess(false);
      setStep(step + 1);
      // GTM tracking event 
      // gtmEvent({ formType: 'Property Valuation', formName: 'Instant Online Valuation Request' })
      // lets send mail
      postData['email_temp_user'] = 'valuation_req_user';
      postData['email_temp_admin'] = 'valuation_req_admin';
      postData['email_subject_user'] = 'Strakers - Instant Valuation';
      postData['email_subject_admin'] = 'Strakers - Instant Valuation';
      // postData['to_bcc'] = "marketing@strakers.co.uk";
      // postData['from_bcc'] = "marketing@strakers.co.uk";
      postData['to_admin'] = data.glstrapi?.offices?.filter(list => list.Office_Name?.includes(formState?.branch?.toLowerCase()))[0]?.Office_Email?.toLowerCase()
      await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/form`, {
        method: `POST`,
        mode: "no-cors",
        headers: {
          'Access-Control-Allow-Origin': '*',
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: qs.stringify(postData),
      })

    });
  }

  const errClass = (field) => {
    
    if (errors[field]) {
      return 'field-error';
    }
    return ''
  }
  var options = [];
  data?.glstrapi?.offices?.map((item) => {
    if(item.Office_Name === "sales progression")
    {

    }else {
        options.push({
            label: toCapitalize(item.Office_Name), value: item.Office_Name
        })    
    }
})
  return (
    // <div className="instant-valuation-wrapper">
      <section className='form-section' id="form-section" >
      <div className="form form--contact bg-white">
    <div className="form__body">
          <div className="form-row">
{/* {JSON.stringify(formState)} */}
{/* {JSON.stringify(errors)} */}

      {step !== 3 &&
        <Row>
          <Col>
            <div className="form-card-wrapper">
            <div className="instant-form-wrapper mt-24">
                <form className="mt-16 valuation-form" ref={myRef}>

                  {
                    errorFlag &&
                    <div class="alert alert-danger alert-error"><p>Highlighted fields are required | invalid</p></div>
                  }

                  {step === 1 &&
                    <div className='form-inputs'>
                    <Row>
                                          <Col md={12}>
                      <PostcodeField
                        name="postcode"
                        label="Property Address"
                        type="text"
                        placeholder="Enter your postcode"
                        postcodeChange={postcodeChange}
                        value={formState?.address}
                        selectHandelChange={selectHandelChange}
                        className={`mb-24 ${errClass('postcode')} ${errClass('address')}`}

                      />
                      </Col>
                      </Row>
                      <Row>
                                          <Col md={12}>
                      <ReactSelect
                        label="How many bedrooms does it have?"
                        value={bedroomselected}
                        onChange={(selected) => {
                          selectHandelChange('bedrooms', selected.value)
                          setbedroomselected(selected);
                        }}
                        options={bedOptions}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                        wrapperClassName={`mb-24 ${errClass('bedrooms')}`}
                        classNamePrefix={`reactSelect2`}
                      />
                      </Col>
                      </Row>
                      <Row>
                                          <Col md={12}>
                      <ReactSelect
                        label="What are you planning to do with the property?"
                        value={propertySelected}
                        onChange={(selected) => {
                          selectHandelChange('propertyType', selected.value)
                          setPropertyTypeselected(selected);
                        }}
                        options={propertyOptions}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                        wrapperClassName={`mb-24 ${errClass('propertyType')}`}
                        classNamePrefix={`reactSelect2`}
                      />
                      </Col>
                      </Row>
                      {/* <ReactSelect
                        label="When do you plan to move?"
                        value={planSelected}
                        onChange={(selected) => {
                          selectHandelChange('plan', selected.value)
                          setPlanselected(selected);
                        }}
                        options={planOptions}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                        wrapperClassName={`mb-24 ${errClass('plan')}`}
                        classNamePrefix={`reactSelect2`}
                      /> */}
                      {/* <div className="react-select-wrapper">
                        <label>  When do you plan to move?  </label>
                        <div className={`text-field-wrapper`}>
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => {
                              setStartDate(date)
                            }}
                            showTimeSelect
                            dateFormat="MMMM d, yyyy h:mm aa"
                            popperClassName="some-custom-class"
                            popperPlacement="top-end"
                            className="text-field mb-24"
                            minDate={moment().toDate()}
                            placeholderText="Select your preferred date"

                          />
                        </div>
                      </div> */}


                    </div>
                  }
                  {step === 2 &&
                    <div className='form-inputs'>
                      <Row>
                                            <Col md={6}>
                      <TextField
                        name="first_name"
                        type="text"
                        label="First Name"
                        className={`mb-24`}
                        inputClassName="outline-grey-border"
                        placeholder={'Enter your first name'}
                        onChange={(e) => { handleChange(e) }}
                        error={errClass('first_name')}
                        value={formState.first_name}
                      />
                      </Col>
                                            <Col md={6}>
                      <TextField
                        name="last_name"
                        type="text"
                        label="Last Name"
                        className="mb-24"
                        inputClassName="outline-grey-border"
                        placeholder={'Enter your last name'}
                        onChange={(e) => { handleChange(e) }}
                        error={errClass('last_name')}
                        value={formState.last_name}
                      />
                      </Col>
                      </Row>
                      <Row>
                                            <Col md={6}>
                      <TextField
                        name="email"
                        type="email"
                        label="Email Address"
                        className="mb-24"
                        inputClassName="outline-grey-border"
                        placeholder={'Enter your Email Address'}
                        onChange={(e) => { handleChange(e) }}
                        error={errClass('email')}
                        value={formState.email}
                      />
                      </Col>
                      
                                            <Col md={6}>
                      <TextField
                        name="telephone"
                        type="number"
                        label="Phone Number"
                        className="mb-24"
                        placeholder={'Enter your phone number'}
                        inputClassName="outline-grey-border"
                        onChange={(e) => { handleChange(e) }}
                        error={errClass('telephone')}
                        value={formState.telephone}
                      />
                      </Col>
                      </Row>
                      <Row>
                                            <Col md={6}>

                                            <SelectBox
                                                    label="Branch"
                                                    name={`branch`}
                                                    options={options}
                                                    onChange={(handleChangeBranch)}
                                                    selected={formState[`branch`]}
                                                    defaultValue={{
                                                      label: props?.branch
                                                        ? get(options.filter(c => c.value?.toLowerCase() === props?.branch?.toLowerCase()), '0.label')
                                                        : "Chippenham",
                                                      value: props?.branch ? props?.branch : "chippenham",
                                                    }}
                                                 
                                                />
                                            </Col>

                                            {/* <Col md="6">
                                                <SelectBox
                                                    label="Do you want to Sell or Let the property?"
                                                    name={`department`}
                                                    options={[
                                                        { label: 'Sales', value: 'Sales' },
                                                        { label: 'Rent', value: 'Rent' }]}
                                                    handleChange={(handleChange)}
                                                    selected={formState[`department`]}
                                                />
                                                
                                            </Col> */}
                                        </Row>
                    </div>
                  }
                  {step !== 3 &&
                    <button
                      variant="tertiary"
                      type="submit"
                      className="max-width-100 mt-24 mb-24 btn btn--yellow fz14 fw-bolder"
                      disabled={btnProcess}
                      onClick={(event) => { handleSubmit(event, step) }}
                    >
                      {step === 1 ? 'Next' : 'Get my valuation'}
                    </button>
                  }

{step === 2 &&
                    <button
                      variant="tertiary"
                      type="submit"
                      className="max-width-100 mt-24 mb-24 btn btn--outline fz14 fw-bolder"
                      disabled={btnProcess}
                      onClick={(event) => { setStep(1) }}
                    >
                      Back
                    </button>
                  }

                </form>

                {/* {step === 1 &&
                  <ModuleText
                    text={"By proceeding, you agree to the terms set out in our <a href='/privacy-policy/'> Privacy Policy</a></p>"}
                    className="terms-text text-center mb-0"
                  />
                }
                {step === 2 &&
                  <ModuleText
                    text={"Want a more accurate valuation? <a href='/property-valuation/home-visit-valuation/'>  Arrange a Free Home Visit Valuation</a></p>"}
                    className="terms-text text-center mb-0"
                  />
                } */}
              </div>
            </div>
          </Col>
        </Row>
      }

      {step === 3 &&
        <Result  {...formState}
        leadProResult={leadProResult}
         />
      }
      </div>
      </div>
      </div>
</section>
    // </div>
  )
}

export default InstantValuationForm