/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */
import Bg from '../bg/bg';
import RoundedBox from '../rounded-box/rounded-box';
import ReviewsRow from '../reviews-row/reviews-row';
import ValuationForm from '../../blocks/valuation-form/valuation-form';
import './styles/_index.scss';
import { Link } from 'gatsby';
import Button from "../button/button"
import { useStaticQuery, graphql } from "gatsby"
import ContentBlock from "../content/Content";
const ValuationLandingList = (props) => {
  // const data = useValuationLandingIntro();
  let processedImages = JSON.stringify({})
  if (props?.imagetransforms?.Banner_Image_Transforms) {
    processedImages = props.imagetransforms.Banner_Image_Transforms
  }
  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      valuation(publicationState: LIVE) {
        Add_Valuation_Item {
          Content
          List
          Title
          Valuation_Cta {
            Label
            Link
            Link_Type
            Url {
              Alias
            }
          }
        }
      }
    }
  }
`)
  return (
    <Bg
      className="valuation-landing-intro"
      // image={data.childImageSharp.gatsbyImageData}
      image={props.Banner_Image}
          alt={props.Banner_Image?.alternativeText}
          imagetransforms={processedImages}
          id={props.pageid}
          Pagename={props.Pagename}
          imgname={props.imgname}
    >
      <Bg.Head>
        <h1>{props?.Title}</h1>
        <p className="body-xl">
        <ContentBlock Content={props.Content} />
        </p>
      </Bg.Head>
      <Bg.Content>
        <div className='d-flex justify-content-center align-items-stretch box-blk'>
          {data?.glstrapi?.valuation?.Add_Valuation_Item?.map((item, i) => {
            return(
              <div className='item'>
                <div className='min-height-val'>
                <h3>{item?.Title}</h3>
                <ContentBlock Content={item?.Content} />
                <div className='d-none d-md-block'>
                <ContentBlock Content={item.List} />
                </div>
                </div>
                <Button link={item?.Valuation_Cta?.Link} href={item?.Valuation_Cta?.Url?.Alias} color={i === 0 ? "yellow" : "blue"}>
                {item?.Valuation_Cta?.Icon_Name &&
                <Button.Icon>
                  <i className={`icon icon--${item?.Valuation_Cta?.Icon_Name}`} />
                </Button.Icon>
                }
                <Button.Text>{item?.Valuation_Cta?.Label}</Button.Text>
              </Button>

              </div>    
            )
          })}

        </div>
        
      </Bg.Content>
    </Bg>
  )
}

export default ValuationLandingList