import React, { useRef, useState, useEffect } from "react"
/**
 * Components
 */
import { Link } from "gatsby"
// import axios from "axios"
import { Container, Row, Col } from "react-bootstrap"
import VideoBanner from "./video-banner/video-banner"
import SubscribetoCatalogueForm from "../Forms/SubscribetoCatalogue";
import Modal from 'react-bootstrap/Modal';

import HomeIntro from "../components/home-intro/home-intro"
import HomeSearchForm from "../blocks/home-search-form/home-search-form"
import HomeInfo from "../components/home-info/home-info"
import HomeGallery from "../components/home-gallery/home-gallery"
import HomeBanner from "../components/home-banner/home-banner"
import HomeNews from "../components/home-news/home-news"
import HomeProperties from "../components/home-properties/home-properties"
import HomeContact from "../components/home-contact/home-contact"
import HomeGalleryCarousel from "../components/home-gallery-carousel/home-gallery-carousel"
import LoadExternalScript from "../components/utils/load-external-script"
import Statistics from "./home-info/statistics"
import BuyersGuideSteps from "./buyers-guide-steps/buyers-guide-steps"
import NewsSales from "./news-sales/news-sales"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"
import ContentBlock from "../components/content/Content";
import NewsAuctions from "./news-auctions/news-auctions"
import News from "../components/news/news"
import Vlogs from "../components/news/vlogs"
import NewsLand from "./news-land/news-land"
import CustomerReviews from "./news-land/customer-reviews"
import AreaGuidePositions from "./area-guide-positions/area-guide-positions"
import StaffVideos from "./news-land/staff-videos"
import TermsBlockInfo from "./property-info/terms-block-info"
import ValuationLandingIntro from "./valuation-landing-intro/valuation-landing-intro"
import InstantValuationIntro from "./instant-valuation-intro/instant-valuation-intro"
import ValuationLandingList from "./valuation-landing-list/valuation-landing-list"
import AreaGuideReviews from "./area-guide-reviews/area-guide-reviews"
import AuctionProperties from "./home-properties/auction-properties"
import LandProperties from "./home-properties/land-properties"
import NewHomesProperties from "./home-properties/new-homes-properties"
import Awards from "./news-land/awards"
import SellOrLet from "./valuation-landing-intro/sell-or-let"
const instaScript = "https://apps.elfsight.com/p/platform.js"
const Modules = props => {

  const myRef = useRef(null)
  const executeScroll = () => {
      myRef?.current?.scrollIntoView({ behavior: 'smooth'})
  }

  const myRefscroll = useRef(null)
  const downexecuteScroll = () => {
    myRefscroll?.current?.scrollIntoView({ behavior: 'smooth'})
  }
  var arr = false;
  if(props?.Modules && props?.Modules?.length > 0 && props?.Modules[props?.Modules?.length - 1]?.__typename === "GLSTRAPI_ComponentComponentBannerCtaBlock" && props.Page?.Pagename !== "Meet Our Team"){
    arr = true
  }

  const [showForm, setShowForm] = useState(false);
  const openform = (val) => {
    setShowForm(val)

}

  return (
    <div 
    className={arr ? "pb-48 pb-120" : ""}
    >
      {props?.Page?.Banner_Type === "No_Banner" ? <></>
      : props?.Page?.Banner_Type === "Valuation" ? <></>
        :
        props.Page?.Pagename === "Home" ?
          <HomeIntro
            Pagename={props.Page?.Pagename}
            Alias={props.Page?.Alias}
            Banner_Image={props.Page?.Banner_Image}
            Banner_Video={props.Page?.Banner_Video}
            BG_Video_Desktop={props.Page?.Background_Video_Desktop?.url}
            BG_Video_Mobile={props.Page?.Background_Video_Mobile?.url}
            BG_Video_Desktop2={props.Page?.Background_Video_Desktop2?.url}
            BG_Video_Mobile2={props.Page?.Background_Video_Mobile2?.url}
            imagetransforms={props.Page?.imagetransforms}
            id={props.Page.id}
          />
          :
          <VideoBanner
            Pagename={props.Page?.Pagename}
            Alias={props.Page?.Alias}
            Banner_Image={props.Page?.Banner_Image}
            Banner_Video={props.Page?.Banner_Video}
            imagetransforms={props.Page?.imagetransforms}
            id={props.Page.id}
            Banner_Content={props.Page?.Banner_Content}
            Cta_Links={props.Page?.Cta_Links}
            Banner_Title={props.Page.Banner_Title}
            Banner_Type={props?.Page?.Banner_Type}
            imgname={"page.Banner_Image.bannerimg"}
            Show_Banner_Form={props.Page?.Show_Banner_Form}
            Show_Review={props.Page?.Show_Review}
            executeScroll={executeScroll}
            downexecuteScroll={downexecuteScroll}
            Videoask={props?.Page?.Videoask}
          />
      }
      {props.Page?.Pagename === "Home" ?
        <></>
        :
        <Breadcrumbs
          className="news-breadcrumbs"
          Page={props.Page?.Pagename}
          alias={props.Page?.Alias}
          social={props.Page?.Social_Share}
        />}
      {props.Page?.Show_Form &&
        <HomeSearchForm />
      }
      <div className="scroll-margin-top" ref={myRefscroll}></div>

      {props.Modules && props.Modules?.length > 0
        ? props.Modules?.map((Module, i) => {
          return (
            <div key={i}>
              {Module.__typename ===
                "GLSTRAPI_ComponentComponentAddValuation" &&  Module.Type === "Home_Visit" &&(
                  <ValuationLandingIntro
                    Pagename={props.Page?.Pagename}
                    Alias={props.Page?.Alias}
                    Banner_Image={props.Page?.Banner_Image}
                    imagetransforms={props.Page?.imagetransforms}
                    pageid={props.Page.id}
                    Banner_Content={props.Page?.Banner_Content}
                    Banner_Title={props.Page.Banner_Title}
                    imgname={"page.Banner_Image.bannerimg"}
                    {...Module}
                   />
                )}
                 {Module.__typename ===
                "GLSTRAPI_ComponentComponentAddValuation" &&  Module.Type === "Sell_or_Let" &&(
                  <SellOrLet
                    Pagename={props.Page?.Pagename}
                    Alias={props.Page?.Alias}
                    Banner_Image={props.Page?.Banner_Image}
                    imagetransforms={props.Page?.imagetransforms}
                    pageid={props.Page.id}
                    Banner_Content={props.Page?.Banner_Content}
                    Banner_Title={props.Page.Banner_Title}
                    imgname={"page.Banner_Image.bannerimg"}
                    {...Module}
                   />
                )}
                {Module.__typename ===
                "GLSTRAPI_ComponentComponentAddValuation" &&  Module.Type === "Instant" && (
                <InstantValuationIntro
                Pagename={props.Page?.Pagename}
                Alias={props.Page?.Alias}
                Banner_Image={props.Page?.Banner_Image}
                imagetransforms={props.Page?.imagetransforms}
                pageid={props.Page.id}
                Banner_Content={props.Page?.Banner_Content}
                Banner_Title={props.Page.Banner_Title}
                imgname={"page.Banner_Image.bannerimg"}
                {...Module}
                />
                )}
                 {Module.__typename ===
                "GLSTRAPI_ComponentComponentAddValuation" &&  Module.Type === "Landing" && (
                <ValuationLandingList 
                Pagename={props.Page?.Pagename}
                Alias={props.Page?.Alias}
                Banner_Image={props.Page?.Banner_Image}
                imagetransforms={props.Page?.imagetransforms}
                pageid={props.Page.id}
                Banner_Content={props.Page?.Banner_Content}
                Banner_Title={props.Page.Banner_Title}
                imgname={"page.Banner_Image.bannerimg"}
                {...Module}
                />
                )}
              {Module.__typename ===
                "GLSTRAPI_ComponentComponentAboutContent" && (
                  <HomeInfo 
                  Videoask={props?.Page?.Videoask}
                  Pagename={props.Page?.Pagename} Alias={props.Page?.Alias} {...Module}
                   />
                )}
              {Module.__typename ===
                "GLSTRAPI_ComponentComponentStatisticsBlock" && (
                  <Statistics Pagename={props.Page?.Pagename} Alias={props.Page?.Alias} {...Module} />
                )}

              {Module.__typename ===
                "GLSTRAPI_ComponentComponentServicesCta" && (
                  <>
                  <Modal className="teamdetails popupForm" backdrop="static" size="lg" show={showForm} 
      onHide={() => openform(false)}
      >
                                            <Modal.Header closeButton>
                                                <Modal.Title> <div className="form__head">
          <h4>Subscribe to Catalogue</h4>
        </div>
</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body> 
                                                <SubscribetoCatalogueForm
                                             />  
                                            </Modal.Body>

                                        </Modal>
                  <HomeGallery openform={openform} showForm={showForm} Pagename={props.Page?.Pagename} Alias={props.Page?.Alias} {...Module} />
                  </>
                )}


              {Module.__typename ===
                "GLSTRAPI_ComponentComponentModules" && Module?.Select_Module === "Recent_properties"
                || "GLSTRAPI_ComponentComponentModules" && Module?.Select_Module === "Recent_sales_properties"
                || "GLSTRAPI_ComponentComponentModules" && Module?.Select_Module === "Recent_rent_properties"
                ?
                (
                  <HomeProperties 
                  isHome={props.Page?.Pagename === "Home" ? true : false}
                   Title={Module.Title}
                   Select_Module={Module?.Select_Module}
                    Cap_Title={Module.Cap_Title}
                    Pagename={props.Page?.Pagename} Alias={props.Page?.Alias} {...Module} />
                ) : null }
                              {Module.__typename ===
                "GLSTRAPI_ComponentComponentModules" && Module?.Select_Module === "Past_auctions" &&
                (
                  <AuctionProperties Title={Module.Title}
                    Cap_Title={Module.Cap_Title}
                    listname={"Past_auctions"}
                    Pagename={props.Page?.Pagename} Alias={props.Page?.Alias} {...Module} />
                )}
                  {Module.__typename ===
                "GLSTRAPI_ComponentComponentModules" && Module?.Select_Module === "Current_auctions" &&
                (
                  <AuctionProperties Title={Module.Title}
                    Cap_Title={Module.Cap_Title}
                    listname={"Current_auctions"}
                    Pagename={props.Page?.Pagename} Alias={props.Page?.Alias} {...Module} />
                )}
                
                {Module.__typename ===
                "GLSTRAPI_ComponentComponentSoldModule" &&
                (
                  <LandProperties Title={Module.Title}
                    Cap_Title={Module.Cap_Title}
                    listname={"sold_land"}
                    Pagename={props.Page?.Pagename} Alias={props.Page?.Alias} {...Module} />
                )}
                {Module.__typename ===
                "GLSTRAPI_ComponentComponentModules" && Module?.Select_Module === "Land_properties" &&
                (
                  <LandProperties Title={Module.Title}
                    Cap_Title={Module.Cap_Title}
                    listname={"land"}
                    Pagename={props.Page?.Pagename} Alias={props.Page?.Alias} {...Module} />
                )}


{Module.__typename ===
                "GLSTRAPI_ComponentComponentModules" && Module?.Select_Module === "Current_Development" &&
                (
                  <NewHomesProperties Title={Module.Title}
                    Cap_Title={Module.Cap_Title}
                    listname={"Current"}
                    Pagename={props.Page?.Pagename} Alias={props.Page?.Alias} {...Module} />
                )}
                {Module.__typename ===
                "GLSTRAPI_ComponentComponentModules" && Module?.Select_Module === "Previous_Development" &&
                (
                  <NewHomesProperties Title={Module.Title}
                    Cap_Title={Module.Cap_Title}
                    listname={"Previous"}
                    Pagename={props.Page?.Pagename} Alias={props.Page?.Alias} {...Module} />
                )}


              {Module.__typename ===
                "GLSTRAPI_ComponentComponentBannerCtaBlock" ?
                (
                  <HomeBanner
                    Pagename={props.Page?.Pagename} Alias={props.Page?.Alias} {...Module} />
                ) : null}
              {Module.__typename ===
                "GLSTRAPI_ComponentComponentModules" && Module?.Select_Module === "Latest_news_videos" &&
                (
                  <HomeNews Title={Module.Title}
                    Cap_Title={Module.Cap_Title}
                    Cta_Link={Module.Cta_Link}
                    Pagename={props.Page?.Pagename} Alias={props.Page?.Alias} {...Module} />
                )}

{Module.__typename ===
                "GLSTRAPI_ComponentComponentAwards" && 
                (
                  <Awards 
                    Pagename={props.Page?.Pagename} Alias={props.Page?.Alias} {...Module} />
                )}
{Module.__typename ===
                "GLSTRAPI_ComponentComponentCustomerReviews" && 
                (
                  <CustomerReviews 
                    Pagename={props.Page?.Pagename} Alias={props.Page?.Alias} {...Module} />
                )}
{Module.__typename ===
                "GLSTRAPI_ComponentComponentTermsBlock" && 
                (
                
<TermsBlockInfo Pagename={props.Page?.Pagename} Alias={props.Page?.Alias} {...Module} />
                )}
{Module.__typename ===
                "GLSTRAPI_ComponentComponentStaffVideos" && 
                (
                  <StaffVideos 
                    Pagename={props.Page?.Pagename} Alias={props.Page?.Alias} {...Module} />
                )}
                {Module.__typename ===
                "GLSTRAPI_ComponentComponentCollections" && Module?.Select_Collection === "Reviews" &&
                <AreaGuideReviews Pagename={props.Page?.Pagename} Alias={props.Page?.Alias} {...Module} />
                
                }
              {Module.__typename ===
                "GLSTRAPI_ComponentComponentModules" && Module?.Select_Module === "Instagram" &&
                (
                  // <HomeGalleryCarousel Title={Module.Title}
                  //   Cap_Title={Module.Cap_Title}
                  //   Cta_Link={Module.Cta_Link}
                  //   Pagename={props.Page?.Pagename} Alias={props.Page?.Alias} {...Module} />
                  <div className="insta-bg">
                  <Container>
                  <LoadExternalScript
                    src={instaScript}
                    // async="true"
                    defer="true"
                    // loadScript={renderMap}
                    // appendScriptTo="head"
                  />
                  <div className="d-md-flex align-items-stretch justify-content-center insta-feed">
                  <div class="carousel__slide"><div class="gallery-card position-relative d-flex flex-column justify-content-end gallery-card--feed bg-blue text-white justify-content-center align-items-center">
                    <div class="gallery-card__image position-absolute w-100 h-100"></div>
                    <div class="gallery-card__text position-relative"><i class="icon icon--instagram bg-white d-block mx-auto"></i><h5>Strakers Instagram</h5><span class="gallery-card__subtitle">@strakers_estate_agents</span><a href="https://www.instagram.com/strakers_estate_agents/?hl=en" target={"_blank"} class="btn btn--yellow d-flex align-items-center justify-content-center
     
     
     "><div class="btn__text">Follow</div></a></div></div></div>
                  <div class="elfsight-app-a234af7d-a421-4fc2-b8d2-c9a5f4fcb1e6"></div>
                  </div>
                </Container>
                </div>
                )}

              {Module.__typename ===
                "GLSTRAPI_ComponentComponentGetStarted" && (
                  <HomeContact Pagename={props.Page?.Pagename} Alias={props.Page?.Alias} {...Module} />
                )}

              {Module.__typename ===
                "GLSTRAPI_ComponentComponentStepper" && (
                  <BuyersGuideSteps Pagename={props.Page?.Pagename} Alias={props.Page?.Alias} {...Module} />
                )}

              {Module.__typename ===
                "GLSTRAPI_ComponentComponentCollections" && Module?.Select_Collection === "Teams" &&
                (
                  <>
                    <Container>
                      <div className="news-filter">
                      <h1>{Module.Title}</h1>
                      <ContentBlock Content={Module.Content} />
                      </div>
                      </Container>
                    <NewsSales
                      Pagename={props.Page?.Pagename} Alias={props.Page?.Alias} {...Module} />
                      
      </>
                )}
                       {Module.__typename ===
        "GLSTRAPI_ComponentComponentCollections" && Module?.Select_Collection === "Careers" &&
        (<>
        <div ref={myRef}></div>
           <AreaGuidePositions Pagename={props.Page?.Pagename} Alias={props.Page?.Alias} {...Module}  />
              
</>
        )}
                 {Module.__typename ===
                "GLSTRAPI_ComponentComponentCollections" && Module?.Select_Collection === "Areas" &&
                (<div className=" bg-linear">
                    <Container >
                    <div className="news-filter">
                      <h1>{Module.Title}</h1>
                      <ContentBlock Content={Module.Content} />
                      </div>
                      </Container>
                      <NewsLand
                                            Pagename={props.Page?.Pagename} Alias={props.Page?.Alias} {...Module} />
                      
      </div>
                )}
            {Module.__typename ===
                "GLSTRAPI_ComponentComponentCollections" && Module?.Select_Collection === "Branches" &&
                (
                <div className=" bg-linear">
                    <Container>
                    <div className="news-filter">
                      <h1>{Module.Title}</h1>
                      <ContentBlock Content={Module.Content} />
                      </div>
                      </Container>
                <NewsAuctions
                Pagename={props.Page?.Pagename} Alias={props.Page?.Alias} {...Module} 
                />
                </div>
          
                )}

{Module.__typename ===
                "GLSTRAPI_ComponentComponentCollections" && Module?.Select_Collection === "News" &&
                <News Pagename={props.Page?.Pagename} Alias={props.Page?.Alias} {...Module}  />
                }

{Module.__typename ===
                "GLSTRAPI_ComponentComponentCollections" && Module?.Select_Collection === "Vlogs" &&
                <Vlogs Pagename={props.Page?.Pagename} Alias={props.Page?.Alias} {...Module}  />
                }



            </div>
          )
        })
        : ""}




    </div>
  )
}
export default Modules
