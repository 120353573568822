/**
 * External dependencies
 */
import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import moment from 'moment';
/**
 * Internal dependencies
 */
import Review from "../review/review"
import axios from "axios"
import "./styles/_index.scss"
import TrustpilotReviews from "@components/trustpilot"
import ContentBlock from "../content/Content";
import { useQuery } from "@apollo/client"
import { useStaticQuery, graphql } from "gatsby";
import Button from "../button/button"
const postsPerPage = 6;
let arrayForHoldingPosts = [];
const AreaGuideReviews = (props) => {
//   const GET_REVIEW_DETAILS = gql`
//   query GetReviews {
//     reviews(sort:"Date:DESC"){
      // Name
      // Title
      // Date
      // Message
      // Publish
      // Rating
     
//     }
//   }
// `

// const { loading, error, data } = useQuery(GET_REVIEW_DETAILS)
// if(data){
//   setTestimonials(data?.reviews)
// }
// console.log(data)
const data = useStaticQuery(graphql`
query{
  glstrapi {
    reviews(sort:"Date:DESC", where: {Publish: true}) {
      Name
      Title
      Date
      Message
      Publish
      Rating
      }
  }
}
`);
  const [] = useState(0);

  const [postsToShow, setPostsToShow] = useState([]);
  const [next, setNext] = useState(6);

  const [] = useState(true);
  const [testimonials, setTestimonials] = useState(data?.glstrapi?.reviews)

  const loopWithSlice = (start, end) => {
      const slicedPosts = testimonials?.slice(start, end);
      arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
      setPostsToShow(arrayForHoldingPosts);
  };

  const handleShowMorePosts = () => {
      loopWithSlice(next, next + postsPerPage);
      setNext(next + postsPerPage);
  };
//   const getitems = async url => {
//     try {
//         const { data } = await axios.get(url, {baseURL: process.env.GATSBY_STRAPI_FORM_URL,
//             headers: {
//             Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
//             }
//         })// could be from env files
//         setTestimonials(data)
//     } catch (error) {
//         // cache it if fail/error;
//         console.error(error)
//     }
// }
useEffect(() => {
    loopWithSlice(0, postsPerPage);
}, [testimonials]);


return(
  <div className="area-guide-reviews-wrapper">
    <Container className="area-guide-reviews">
      <div className="area-guide-reviews__inner pt-48 pb-48 pt-120 pb-120">
        <p className="fz14 mb-1_4 c-grey-39">
          <strong>{props?.Title}</strong>
        </p>
        <ContentBlock Content={props.Content} />
        <Row>
        {postsToShow?.map((review, i) => {
          return(
            <Review
            key={i}
            title={review?.Title}
            author={review?.Name}
            date={moment(review?.Date).format("MM-DD-YYYY")}
            className="mb-2_4 mb-lg-0 pe-lg-4 review-list"
          >
            <p className="body-m">
            {review?.Message}
            </p>
          </Review>
          )
        })}
          {/* <Review
            title="Review of House Sale in Chippenham"
            author="Danny Wootton"
            date="07-11-2022"
            className="mb-2_4 mb-lg-0 pe-lg-4"
          >
            <p className="body-m">
              Strakers did a superb job of selling my late mothers house. They
              had an excellent understanding of the market and the valuation was
              spot on - we had a sale agreed within a few weeks of going to
              market. Their communication was good. The staff were always polite
              and friendly and we were kept informed of all developments and
              given regular updates... <Link to="/">more</Link>
            </p>
          </Review>
          <Review
            title="We were kept abreast of our home move…"
            author="Sarah and Andrew"
            date="07-11-2022"
            className="mb-2_4 mb-lg-0 ps-lg-4"
          >
            <p className="body-m">
              We were kept abreast of our home move at every stage, someone
              spoke to us weekly to update us.
            </p>
          </Review> */}
          {/* <TrustpilotReviews
                                language="en"
                                culture="US"
                                theme="light"
                                width="100%"
                                height="150px"
                                template="53aa8807dec7e10d38f59f32"
                                business="592d87f90000ff0005a381bf"
                                // username="balgoresproperty"
                                data-text-color="#e0e0e0"
                                textcolor={'#e0e0e0'}
                                stars="4,5"
                            /> */}
                            {postsToShow?.length > 0 && testimonials && postsToShow?.length !== testimonials?.length &&
                            <Col>
<Button
        className="mt-3_2 mt-lg-6 btn--outline"
        color="tsp"
        borderColor="blue"
        textColor=""
        displayAsLink={false}
        onClick={handleShowMorePosts}
      >
        <Button.Text>Load more</Button.Text>
      </Button>
      </Col>
      }
      
        </Row>
      </div>
    </Container>
  </div>
)
                          }
export default AreaGuideReviews
