/**
 * External dependencies.
 */
import React, {useState} from "react"
import { Col, Row } from "react-bootstrap"
import Modal from 'react-bootstrap/Modal';
/**
 * Internal dependencies.
 */
import Button from "../button/button"
import IntroContent from "../intro-content/intro-content"
import useVideoBanner from "./use-video-banner"
import VideoPlaceholder from "../video-placeholder/video-placeholder"
import { convertGatsbyImageNodes } from "../../utils/gatsby-images"
import "./styles/_index.scss"
import ContentBlock from "../content/Content";
import TrustpilotReviews from "@components/trustpilot"
import BannerSearchForm from "../../blocks/home-search-form/banner-search-form"
import BasicContactForm from "../../blocks/contact-form/contact-form-basic"
const VideoBanner = (props) => {
  const data = useVideoBanner()
  // const images = convertGatsbyImageNodes(data)
  const [showForm, setShowForm] = useState(false);
  const openform = () => {
      setShowForm(true)
    }
    let processedImages = JSON.stringify({})
    if (props?.imagetransforms?.Banner_Image_Transforms) {
      processedImages = props.imagetransforms.Banner_Image_Transforms
    }
  return (
    <IntroContent fluidContainer={false} 
    image={props.Banner_Image}
    video={props.Banner_Video}
          alt={props.Banner_Image?.alternativeText}
          imagetransforms={processedImages}
          id={props.id}
          Pagename={props.Pagename}
          imgname={props.imgname}
     className={`area-guide-intro  ${props?.Banner_Type} ${props?.Videoask ? " videoask" : ""}`}>
      <div className={`intro-content__content`}>
        <h1 className="mb-1_6">{props.Banner_Title}</h1>
        <ContentBlock Content={props.Banner_Content} />
        {props?.Show_Banner_Form &&
        <BannerSearchForm />
        }
        {props?.Show_Review &&
          <TrustpilotReviews
          language="en"
          culture="US"
          theme="dark"
          width="100%"
          height="150px"
          template="53aa8807dec7e10d38f59f32"
          business="592d87f90000ff0005a381bf"
          // username="balgoresproperty"
          data-text-color="#e0e0e0"
          textcolor={'#fff'}
          stars="4,5"
      />
        }
        {props?.Cta_Links?.length > 0 &&
        <Row className="intro-content__cta">
          {props?.Cta_Links?.map((item, i) => {
            return(
              <Col xs="12" md="6" className={i === 0 ? "mb-4 pe-md-3 mb-md-0" : "mb-4 ps-md-4 mb-md-0"}>
              <Button isExternalLink={item?.Link_Type === "External" ? true : false} onClick={item?.Link_Type === "Form" ? openform : props?.executeScroll} displayAsLink={props?.Pagename === "Careers" || item?.Link_Type === "Form" ? false : true} link={item?.Link} href={item?.Url?.Alias} color={i === 0 ? "yellow" : "blue"}>
                {item?.Icon_Name &&
                <Button.Icon>
                  <i className={`icon icon--${item?.Icon_Name}`} />
                </Button.Icon>
                }
                <Button.Text>{item?.Label}</Button.Text>
              </Button>
              {item?.Link_Type === "Form" &&
              <Modal className="officedetails popupForm" backdrop="static" size="lg" show={showForm} onHide={() => { setShowForm(false);}}>
            <Modal.Header closeButton>
              <Modal.Title> <div className="form__head">
                <h4>Get in touch</h4>
              </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <BasicContactForm Email={item?.Email} popup={true} />
            </Modal.Body>

          </Modal>
          }
            </Col>  
            )
          })}
        </Row>
        }
        {props?.Banner_Type !== "Half_Height" &&
        <div className="opacity-60">
          <button className="clear-btn jump-icon-hover" onClick={props?.downexecuteScroll}>
            <Row className="intro-content__scroll align-items-center">
              <Col xs="auto" className="pe-0 me-0_8">
                <i className="icon icon--scroll"></i>
              </Col>
              <Col className="ps-0">
                <p className="body-xs mb-0 d-block d-md-none">
                  Or scroll for more information
                </p>
                <p className="body-xs mb-0 d-none d-md-block d-lg-none">
                  Or scroll for more information about {props.Banner_Title}
                </p>
                <p className="body-xs mb-0 d-none d-lg-block">
                  Or scroll for more information about {props.Banner_Title}
                </p>
              </Col>
            </Row>
          </button>
        </div>
      }
      </div>
      {props?.Videoask &&
      <div className="area-guide-intro__video d-none d-lg-block position-absolute">
      <iframe src={props?.Videoask}
      className="videoask-frame"
      allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
>
</iframe>
      </div>}
     
    </IntroContent>
  )
}

export default VideoBanner
