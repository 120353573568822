/**
 * External dependencies
 */
import classNames from "classnames"
import React from "react"
import { Col, Row } from "react-bootstrap"

/**
 * Internal dependencies
 */
import "./styles/_index.scss"

const Step = ({ className, children, step, customref }) => (
  <Row className={classNames('step g-0', className)} ref={customref}>
    {step && (
      <span className="step__side">
        {/* <span className="step__side-inner">step</span> */}
        {/* <div className="step__side-number">{step}</div> */}
      </span>
    )}
    {children}
  </Row>
)
const StepContent = ({ children }) => (
  <Col className="step-content">{children}</Col>
)
const StepImage = ({ children }) => <Col className="step-image">{children}</Col>

Step.Content = StepContent
Step.Image = StepImage

export default Step
