/**
 * External dependencies
 */
import { useStaticQuery, graphql } from "gatsby"

const useHomeGallery = () => {
  const { file: image } = useStaticQuery(graphql`
    query HomeIntro {
      file(relativePath: {eq: "home-intro/images/nature.png"}) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
 `);

  return image;
}

export default useHomeGallery;
