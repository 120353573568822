/**
 * External dependencies
 */
import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import classNames from "classnames"

/**
 * Internal dependencies
 */
import lands from "./static/lands"
import CardLand from "./card-land"
import useNewsLand from "./use-news-land"
import {
  GUIDE_PAGE_URL
} from "../../components/common/site/constants"
import { convertGatsbyImageNodes } from "../../utils/gatsby-images"
import "./styles/_index.scss"
import { useStaticQuery, graphql } from "gatsby";
const NewsLand = () => {
  const data = useStaticQuery(graphql`
    query{
      glstrapi {
        areaGuides(publicationState: LIVE, sort: "Title:ASC", where: {Publish: true}) {
          id
          imagetransforms
          Title
          Tile_Image {
            alternativeText
            url
          }
          Video_Url
          URL
        }
      }
    }
    `);

  return (
    <Container className="news-land">
      <Row className="news-land__row flex-column flex-md-row">
      {data.glstrapi?.areaGuides?.map((x, index) => {
          let processedImages = JSON.stringify({})
          if (x.imagetransforms?.Tile_Image_Transforms) {
            processedImages = x.imagetransforms.Tile_Image_Transforms
          }
        return(
          <Col
            key={x.Title}
            className={classNames("news-land__col", {
              // "d-none d-lg-block": index >= 2,
            })}
          >
            <CardLand
              imagetransforms={processedImages}
              id={x.id}
                image={x.Tile_Image}
                video={x.Video_Url}
                title={x.Title}
                href={GUIDE_PAGE_URL.alias+'/'+x.URL+'/'}
                index={index}
                imgname={"area-guide.Tile_Image.list"}
            />
          </Col>
        )})}
      </Row>
    </Container>
  )
}

export default NewsLand
