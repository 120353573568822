/**
 * External dependencies
 */
 import React, { useState, useEffect } from "react";
import { Container, Col } from "react-bootstrap"
import Button from "../button/button"
/**
 * Internal dependencies
 */
import Position from "../position/position"
import "./styles/_index.scss"
import ContentBlock from "../content/Content";
import { toCapitalize } from "@components/common/utils";
import {
  CAREERS_PAGE_URL
} from "../../components/common/site/constants"
import { useStaticQuery, graphql } from "gatsby";
const AreaGuidePositions = (props) => {
  const data = useStaticQuery(graphql`
  query{
    glstrapi {
      careers(sort: "createdAt:DESC", publicationState: LIVE, where: {Publish: true}) {
          id
          URL
          Publish
          Job_Title
          Department
          Branch {
            Office_Name
          }
        }
    }
  }
  `);
  const [careers, setCareers] = useState(data.glstrapi?.careers)
  const postsPerPage = 3;
	let arrayForHoldingPosts = [];
	// const [] = useState(0);
	const [postsToShow, setPostsToShow] = useState([]);
	const [next, setNext] = useState(3);
	const loopWithSlice = (start, end) => {
        const slicedPosts = careers?.slice(0, end);
        if(careers){
            arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
            setPostsToShow(arrayForHoldingPosts);
        }

	};
  const handleShowMorePosts = () => {
		loopWithSlice(next, next + postsPerPage);
		setNext(next + postsPerPage);
	};

	useEffect(() => {
		loopWithSlice(0, postsPerPage);
        setNext(3)
	}, []);
  return(
  <div id="open-positions" className="area-guide-positions-wrapper pt-48 pb-48 pt-120 pb-120">
    <Container className="area-guide-positions">
      <p className="fz14 mb-2_0 c-grey-39">
        <strong>{props?.Title}</strong>
      </p>
      <ContentBlock Content={props.Content} />
      {postsToShow?.map((x, index) => (
      <Position key={index} title={x.Job_Title} link={`${CAREERS_PAGE_URL?.alias}/${x.URL}`}>
        <Col className="position__body body-m mb-0 d-none d-lg-block">
        {x?.Branch?.map((list, index) => (
                            <>
                              {toCapitalize(list.Office_Name)}
                              {index === x?.Branch?.length - 1
                                ? ""
                                : ", "}
                            </>
                          ))}
        </Col>
        {x.Department && <Col className="position__body body-m mb-0 d-none d-lg-block">
          {x.Department}
        </Col>}
      </Position>
      ))}
      {postsToShow?.length > 0 && careers && postsToShow?.length !== careers?.length &&
      <Col className="">
<Button
        className="mt-3_2 mt-lg-6 btn--outline"
        color="tsp"
        borderColor="blue"
        textColor=""
        displayAsLink={false}
        onClick={handleShowMorePosts}
      >
        <Button.Text>Load more</Button.Text>
      </Button>
      </Col>}
    </Container>
  </div>
)
  }
export default AreaGuidePositions
