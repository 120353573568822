/**
 * External dependencies
 */
 import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container } from "react-bootstrap"
import LinkIcon from "../link-icon/link-icon"
import { StaticImage } from "gatsby-plugin-image"
import ContentBlock from "../content/Content";
import { graphql } from "gatsby"
/**
 * Internal dependencies
 */
import 'videoask-elements';
import BulletInfo from "../bullet-info/bullet-info"
import bulletData from "./static/bullet-data"
import "./styles/_index.scss"
import TrustpilotReviews from "@components/trustpilot"
import ReactMarkdown from "react-markdown"
import Accordions from "../accordion/accordion"
import { inViewOptions, containerVariants, contentItem, fadeInFromTop } from '../common/animation';
import {
  isIOS,
  isMobile,
  isTablet
} from "react-device-detect";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
const MotionColumn = motion(Col)
const HomeInfo = ({Content,Title,
  Show_Share,
  Show_Review,
  Toggle_Component,
  Single_Cta_Button,
  Statistic, Pagename, Videoask }) => { 
    const myRef = useRef(null)
  
    const [isClient, setClient] = useState(false);
    const [active, setActive] = useState('+ Read More');
    const handleAccordionClick = () => {
      if (active === '+ Read More') {
          setActive('- Read Less')
          myRef?.current?.scrollIntoView({ behavior: 'smooth'})

      } else {
          setActive('+ Read More')
          myRef?.current?.scrollIntoView({ behavior: 'smooth'})

      }
  }
  if (Content && Content?.length < 600) {
    var long_desc_first_part = Content;
  }
  else {
    var long_desc_first_part = Content ? Content?.split(/\s+/)?.slice(0, 60)?.join(" ") : '';
  }
  useEffect(() => {
    setClient(true);
  }, [])
    return(
      <div className={Pagename === "Home" && !isMobile && !isTablet ? "home-info pt-48 pb-48" : "bg-linear home-info pt-48 pb-48"}>
    <Container>
      <Row className={"first g-0"}>
      <div className="scroll-margin-top" ref={myRef}></div>
      <Col className="d-lg-none d-block">
      {Videoask && isMobile &&
//       <iframe src={Videoask}
//       className="videoask-frame"
//       width={100}
//       allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
// ></iframe>
<videoask-widget url={Videoask} 
type={"VideoThumbnailExtraLarge"}
position={"bottom-right"}

></videoask-widget>
}

      </Col>
        <Col lg>
        <ContentBlock Content={Title} />
        {Show_Review &&
          <TrustpilotReviews
                                language="en"
                                culture="US"
                                theme="light"
                                width="100%"
                                height="150px"
                                template="53aa8807dec7e10d38f59f32"
                                business="592d87f90000ff0005a381bf"
                                // username="balgoresproperty"
                                data-text-color="#e0e0e0"
                                textcolor={'#e0e0e0'}
                                stars="4,5"
                            />
          }
        </Col>
        <Col md className="home-info__text p-spacing">
          {/* <ContentBlock Content={Content} /> */}
          <>
          
      {isClient && active === '+ Read More' ?
        <ReactMarkdown source={Content?.length > 600 ? long_desc_first_part+'...' : long_desc_first_part} allowDangerousHtml /> 
        :
        <ReactMarkdown source={Content} allowDangerousHtml /> 
        
  }
        {isClient && Content?.length > 600 && <a className="read-more custom-link" onClick={handleAccordionClick}>{active}</a>}
        </> 
          {Single_Cta_Button?.Url?.Alias &&
          <LinkIcon href={Single_Cta_Button?.Url?.Alias} text={Single_Cta_Button?.Label} />
          }
           
           {Toggle_Component?.length > 0 &&
           <>
           {/* <div className="border-bottom d-block d-md-none mt-4_0 mb-4_0"></div> */}
          <div className="area-guide-info__accordions border-top">
            <Accordions>
              {Toggle_Component?.map((x, index) => (
                <Accordions.Item eventKey={index} title={x.Title} key={index}>
                  <ContentBlock Content={x.Content} />
                </Accordions.Item>
              ))}
            </Accordions>
          </div>
          </>
          }
        </Col>
      </Row>
      {Statistic?.Add_Statistic &&
      <Row>
        <Col>
      <div className="border-bottom mt-4_0 mb-4_0"></div>
      </Col>
      </Row>
  }
  <InView  {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}

        >
  {Statistic?.Add_Statistic?.length> 0 &&
      <Row className="home-info__bullets pt-14 pb-14">
        {Statistic?.Add_Statistic?.map((bullet, index) => (
          // <Col className="col-bullet" lg key={index}>
            <MotionColumn className="col-bullet" key={index} lg variants={contentItem}>
            <BulletInfo
              icon={bullet.Icon}
              title={bullet.Percentage}
              description={bullet.Content}
              Prefix={bullet?.Prefix}
              Suffix={bullet?.suffix}
              inView={inView}
            />
          </MotionColumn>
        ))}
      </Row>
  }

</motion.div>
      )}
    </InView>
     </Container>
  </div>
)
        }

export default HomeInfo
export const aboutContentFragment = graphql`
    fragment AboutContentFragment on GLSTRAPI_ComponentComponentAboutContent {
        id
        Content
        Title
        Show_Share
        Show_Review
        Toggle_Component {
          Content
          Title
        }
        Single_Cta_Button {
          Label
          Link
          Url {
            Alias
          }
        }
        Statistic {
          Publish
          Title
          Add_Statistic {
            Content
            Percentage
            Prefix
            suffix
            Icon {
              alternativeText
              url
            }
          }
        }
    }
`