/**
 * External dependencies
 */
import React, {useEffect, useState} from 'react';
import { getImage } from 'gatsby-plugin-image';

/**
 * Internal dependencies
 */
import VideoPlaceholder from '../video-placeholder/video-placeholder';
import useHomeIntro from './use-home-intro';

import './styles/_index.scss';

const HomeIntro = (props) => {
  const data = useHomeIntro();
  let processedImages = JSON.stringify({})
  if (props.imagetransforms?.Banner_Image_Transforms) {
    processedImages = props.imagetransforms.Banner_Image_Transforms
  }
const [BG_Video_Desktop, SETBG_Video_Desktop] = useState("")
const [BG_Video_Mobile, SETBG_Video_Mobile] = useState("")

  useEffect(() => {
    
    if(sessionStorage.getItem('video') != null){
      if(sessionStorage.getItem('video') === "video1"){
        SETBG_Video_Desktop(props?.BG_Video_Desktop2)
        SETBG_Video_Mobile(props?.BG_Video_Mobile2)

        sessionStorage.setItem("video", "video2")
      }
      else if(sessionStorage.getItem('video') === "video2"){
        SETBG_Video_Desktop(props?.BG_Video_Desktop)
        SETBG_Video_Mobile(props?.BG_Video_Mobile)
        sessionStorage.setItem("video", "video1")
      }
      else {


      }


    }
    else {
      SETBG_Video_Desktop(props?.BG_Video_Desktop)
      SETBG_Video_Mobile(props?.BG_Video_Mobile)

        sessionStorage.setItem("video", "video1")
    }
  }, []);

  return (
    <div className="home-intro">
      <div className="home-intro__video-block">
        <div className="all-mode">
        <VideoPlaceholder
        video={props.Banner_Video}
        BG_Video_Desktop={BG_Video_Desktop}
        BG_Video_Mobile={BG_Video_Mobile}
          image={props.Banner_Image}
          alt={props.Banner_Image?.alternativeText}
          imagetransforms={processedImages}
          id={props.id}
          disableLazyLoadOnImage={true}
          Pagename={props.Pagename}
          nowrapper={true}
          imgname={"page.Banner_Image.bannerimg"}
        />
        </div>
        <div className='landscape-mode'>
        <VideoPlaceholder
        video={props.Banner_Video}
        BG_Video_Desktop={BG_Video_Desktop}
        BG_Video_Mobile={BG_Video_Desktop}
          image={props.Banner_Image}
          alt={props.Banner_Image?.alternativeText}
          imagetransforms={processedImages}
          id={props.id}
          disableLazyLoadOnImage={true}
          Pagename={props.Pagename}
          nowrapper={true}
          imgname={"page.Banner_Image.bannerimg"}
        />
        </div>
      </div>
    </div>
  )
}

export default HomeIntro;