/**
 * External dependencies.
 */
import React from "react"
import classNames from "classnames"

/**
 * Internal dependencies.
 */
import "./styles/_index.scss"

const RoundedBox = ({ children, className }) => (
  <div className={classNames("rounded-box bg-white", className)}>
    {children}
  </div>
)

export default RoundedBox
