/**
 * External dependencies
 */
import React, { useState, useEffect, useMemo } from "react"
import axios from "axios";

import { Link } from "gatsby"
import { Row, Col, Container } from "react-bootstrap"
import { currencyFormat } from '@components/common/utils';
/**
 * Internal dependencies
 */
import useHomeProperties from "./use-home-properties"
import { convertGatsbyImageNodes } from "../../utils/gatsby-images"
import Card from "../card/card"
import LinkIcon from "../link-icon/link-icon"
import properties from "./static/properties"
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
import "./styles/_index.scss"
import Carousel from "../carousel/carousel"
import classNames from "classnames"
import {
  PAGE_NEWHOMES,
  PAGE_PNEWHOMES,
  TO_RENT_PAGE_URL
} from "@components/common/site/constants"
import {
  isMobile
} from "react-device-detect";
import Modal from 'react-bootstrap/Modal';
import BookAViewingForm from "../../Forms/BookAViewing"
import PropertyEnquireForm from "../../Forms/PropertyEnquire";
const NewHomesProperties = (props) => {
  const [showForm, setShowForm] = useState(false);
  const [index, setIndex] = useState();
  const openform = (x) => {
            setShowForm(!showForm)
          setIndex(x)
  }
  const [propItems, setPropItems] = useState([])
  const [isCLient, setClent] = useState(true)
  const getitems = async url => {
    try {
      const { data } = await axios.get(url, {baseURL: process.env.GATSBY_STRAPI_FORM_URL,
        headers: {
          Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
          // "Content-Type": "multipart/form-data"  
        }})
      // console.log("PropertySimilar", data)
      setPropItems(data)
      setClent(false)
    } catch (error) {
      // console.error(error)
    }
  }

  useEffect(() => {
    let url;
    if(props?.listname === "Current"){
      url = process.env.GATSBY_STRAPI_SRC +
      "/stb-lists/item/current_developments"
      // ?_limit=8&_sort=price:DESC&publish=true
    }
    else if(props?.listname === "Previous"){
      url = process.env.GATSBY_STRAPI_SRC +
      "/stb-lists/item/previous_developments"
    }

    getitems(url)
  }, []);

  // const data = useHomeProperties()
  // const images = useMemo(() => convertGatsbyImageNodes(data), [data])
  const propertiesCollection = useMemo(
    () =>
      propItems.map((x, i) => {
        let uriStr = ""
        let mysale = ""
        let hit = { ...x }
        console.log(hit)
        // mapping
        hit.searchType = hit.searchType || hit.search_type;

        // if (hit.searchType === "sales" && hit.department === "residential") {
          uriStr = `new-homes-for-sale/`
          mysale = `myResSaleProp`
        // } else if (
        //   hit.searchType === "lettings" &&
        //   hit.department === "residential"
        // ) {
        //   uriStr = `property-to-rent/`
        //   mysale = `myResRentProp`
        // }

        let processedImages = JSON.stringify({});
        if (x.imagetransforms?.upload_images_Transforms) {
          processedImages = x.imagetransforms?.upload_images_Transforms;
        }
        return (
          x?.status === "Completed" ? 
          <Card>
            
            <div className={x?.status === "Completed" ? "property-box__badge yellow" : "property-box__badge"}>{x?.status === "Completed" ? "Completed" : "For Sale"}</div>
              {/* <Card.Head onImageHoverZoom image={images[x.image]} alt={x.alt || "property"} /> */}
              <div className="overflow-hidden">
                <div className="hover-zoom">
              <ImageTransform
                imagesources={x?.images[0]?.url}
                // renderer="srcSet"
                imagename="offplan.upload_images.similardetail"
                attr={{ alt: `${x.developer?x.developer:""} - Strakers Estate Agents`, className: "" }}
                imagetransformresult={processedImages}
                id={x.id}
              />
              </div>
              </div>
            <Card.Body>
                <h5>{x.developer}</h5>
                {/* <h5>{x.display_address}</h5> */}
                
              <div className="card__description d-flex">
              {x?.price_qualifier !== "Default" && x?.price_qualifier &&
                <span className="card__subtitle fz14 d-inline-block">
                  {x?.price_qualifier !== "Default" ? x?.price_qualifier : ""}
                </span>
      }
                <span className="fw-bolder card__price fz14">{currencyFormat(x?.price, '£', false)}{currencyFormat(x?.max_price, '£', false, true)}</span>
              </div>

              <p>{x?.title?.replace(/-/g, ' ')}</p>
            </Card.Body>
            {/* <Card.Footer>
              <Row className="g-0 flex-nowrap justify-content-between">
                <Col>
                  <Link class="link-icon blue-link-hover d-flex align-items-center"
                    to={`/${uriStr}${x.url}-${x.id}`}
                  >
                    <div
                      className={`link-icon__text fw-bolder fz14 position-relative order-0`}
                    >
                      Details
                    </div>
                    <i className={classNames(`icon icon--eye`)}></i>
                  </Link>
                </Col>

                <Col>
                  <Card.Delimiter />
                </Col>

                <Col>
                  <a class="link-icon blue-link-hover d-flex align-items-center"
                  // to={`/${uriStr}${hit.url}-${hit.id}`}
                  onClick={(e) => openform(x)}
                  href="javascript:void(0)"
                  >
                    <div
                      className={`link-icon__text fw-bolder fz14 position-relative order-0`}
                    >
                      Enquire
                    </div>
                    <i className={classNames(`icon icon--arrow-up-right`)}></i>
                  </a>
                </Col>
              </Row>
            </Card.Footer> */}
            
          </Card> :
          <Card>
          <Link to={`/${uriStr}${x.url}-${x.id}`}>
          <div className={x?.status === "Completed" ? "property-box__badge yellow" : "property-box__badge"}>{x?.status === "Completed" ? "Completed" : "For Sale"}</div>
            {/* <Card.Head onImageHoverZoom image={images[x.image]} alt={x.alt || "property"} /> */}
            <div className="overflow-hidden">
              <div className="hover-zoom">
            <ImageTransform
              imagesources={x?.images[0]?.url}
              // renderer="srcSet"
              imagename="offplan.upload_images.similardetail"
              attr={{ alt: x.developer+" - Strakers Estate Agents", className: "" }}
              imagetransformresult={processedImages}
              id={x.id}
            />
            </div>
            </div>
          </Link>
          <Card.Body>
            <Link className="address-min-height" to={`/${uriStr}${x.url}-${x.id}`}>
              <h5>{x.developer}</h5>
              {/* <h5>{x.display_address}</h5> */}
            </Link>

            <div className="card__description d-flex">
            {x?.price_qualifier !== "Default" && x?.price_qualifier &&
              <span className="card__subtitle fz14 d-inline-block">
                {x?.price_qualifier !== "Default" ? x?.price_qualifier : ""}
              </span>
    }
              <span className="fw-bolder card__price fz14">{currencyFormat(x?.price, '£', false)} - {currencyFormat(x?.max_price, '£', false)}</span>
            </div>

            <p>{x?.title?.replace(/-/g, ' ')}</p>
          </Card.Body>
          <Card.Footer>
            <Row className="g-0 flex-nowrap justify-content-between">
              <Col>
                <Link class="link-icon blue-link-hover d-flex align-items-center"
                  to={`/${uriStr}${x.url}-${x.id}`}
                >
                  <div
                    className={`link-icon__text fw-bolder fz14 position-relative order-0`}
                  >
                    Details
                  </div>
                  <i className={classNames(`icon icon--eye`)}></i>
                </Link>
                {/* <LinkIcon text="Details" icon="eye" /> */}
              </Col>

              <Col>
                <Card.Delimiter />
              </Col>

              <Col>
                <a class="link-icon blue-link-hover d-flex align-items-center"
                // to={`/${uriStr}${hit.url}-${hit.id}`}
                onClick={(e) => openform(x)}
                href="javascript:void(0)"
                >
                  <div
                    className={`link-icon__text fw-bolder fz14 position-relative order-0`}
                  >
                    Enquire
                  </div>
                  <i className={classNames(`icon icon--arrow-up-right`)}></i>
                </a>
                {/* <LinkIcon text="Enquire" /> */}
              </Col>
            </Row>
          </Card.Footer>
          
        </Card>
        )
      }),
    [propItems]
  )

  return (
    propertiesCollection?.length > 0 ?
    <div className="home-properties pt-48 pb-48 pt-120 pb-120 home-developements">
      <Container className="home-properties__inner">
        <h6 className="home-properties__quote fw-bolder fz14 d-inline-block">
          {props.Cap_Title}
        </h6>

        <Row className="align-items-center align-items-lg-start justify-content-lg-between">
          <Col xs={12} lg="auto">
            <h3>{props.Title}</h3>
          </Col>

          <Col lg="auto">
            <Row className="flex-nowrap home-properties__actions">
              <Col xs="auto" className="home-properties__action-col-1">
                <Link class="link-icon blue-link-hover d-flex align-items-center"
                  to={props?.listname === "Previous" ? PAGE_PNEWHOMES?.alias : PAGE_NEWHOMES?.alias}
                >
                  <div
                    className={`link-icon__text fw-bolder fz14 position-relative order-0`}
                  >
                    View All
                  </div>
                  <i className={classNames(`icon icon--arrow-up-right`)}></i>
                </Link>
                {/* <LinkIcon href="/" text="Property for sale" /> */}
              </Col>

              
            </Row>
          </Col>
        </Row>
      </Container>
      {/* {JSON.stringify(propItems)} */}
      {propertiesCollection?.length > 0 &&
      <Carousel
        className="home-properties__collection"
        data={propertiesCollection}
        controllers={{ buttons: isMobile && propertiesCollection?.length > 1 ? true : !isMobile && propertiesCollection?.length > 3 ? true : false }}
        isFluidContainer={false}
      />}
      
        <Modal className="teamdetails popupForm" backdrop="static" size="lg" show={showForm} onHide={() => { setShowForm(false);setIndex() }}>
                                            <Modal.Header closeButton>
                                                <Modal.Title> <div className="form__head">
          <h4>Enquire about Property</h4>
          {/* {JSON.stringify(index)}
          {index?.searchtype} */}
        </div>
</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body> 
                                                <PropertyEnquireForm
                                                id={index?.id}
                                                 property_img={index?.images && index?.images[0]?.url}
                                                  page_url={`${process.env.GATSBY_SITE_URL}new-homes-for-sale/${index?.url}-${index?.id}`}
                                                   property_title={index?.display_address}
                                                    type={index?.search_type}
                                                data={index}
                                                />  
                                            </Modal.Body>
</Modal>
    </div>
 : isCLient ? <div className="empty-space"><br/></div>
 : null )
}

export default NewHomesProperties
