/**
 * External dependencies
 */
import React, { useState, useEffect, useMemo } from "react"
import axios from "axios";

import { Link } from "gatsby"
import { Row, Col, Container } from "react-bootstrap"
import { currencyFormat } from '@components/common/utils';
/**
 * Internal dependencies
 */
import useHomeProperties from "./use-home-properties"
import { convertGatsbyImageNodes } from "../../utils/gatsby-images"
import Card from "../card/card"
import LinkIcon from "../link-icon/link-icon"
import properties from "./static/properties"
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
import "./styles/_index.scss"
import Carousel from "../carousel/carousel"
import {
  isMobile
} from "react-device-detect";
import classNames from "classnames"
import moment from "moment"
import { ShowProcessedImage } from '@components/common/ggfx-client/module/components/show-image';
import imageConfig from "../../../static/images/config.json"
import {
  PAGE_AUCTION_OLD,
  PAGE_AUCTION_CURRENT
} from "@components/common/site/constants"
import Modal from 'react-bootstrap/Modal';
import BookAViewingForm from "../../Forms/BookAViewing"
const AuctionProperties = (props) => {
  const [showForm, setShowForm] = useState(false);
  const [index, setIndex] = useState();
  const openform = (x) => {
            setShowForm(!showForm)
          setIndex(x)
  }
  const [propItems, setPropItems] = useState([])
  const [isCLient, setClent] = useState(true)
  const getitems = async url => {
    try {
      const { data } = await axios.get(url, {baseURL: process.env.GATSBY_STRAPI_FORM_URL,
        headers: {
          Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
          // "Content-Type": "multipart/form-data"  
        }})
      // console.log("PropertySimilar", data)
      setPropItems(data)
      setClent(false)
    } catch (error) {
      // console.error(error)
    }
  }

  useEffect(() => {
    let url;
    if(props?.listname === "Current_auctions"){
      url = process.env.GATSBY_STRAPI_SRC +
      "/stb-lists/item/current_auctions"
    }
    else if(props?.listname === "Past_auctions"){
      url = process.env.GATSBY_STRAPI_SRC +
      "/stb-lists/item/past_auctions"
    }

    getitems(url)
  }, []);

  // const data = useHomeProperties()
  // const images = useMemo(() => convertGatsbyImageNodes(data), [data])
  const propertiesCollection = useMemo(
    () =>
      propItems.map((x, i) => {
        // console.log(x)
        let uriStr = "auction-property-for-sale/"
        let mysale = ""
        let hit = { ...x }
        // mapping
        hit.searchType = hit.searchType || hit.search_type;

        if (hit.searchType === "sales" && hit.department === "residential") {
          uriStr = `property-for-sale/`
          mysale = `myResSaleProp`
        } else if (
          hit.searchType === "lettings" &&
          hit.department === "residential"
        ) {
          uriStr = `property-to-rent/`
          mysale = `myResRentProp`
        }

        let processedImages = JSON.stringify({});
        if (x.imagetransforms?.images_Transforms) {
          processedImages = x.imagetransforms?.images_Transforms;
        }
        return (
          <Card className="card-let">
    <Link to={`/${uriStr}${hit.slug}-${hit.id}`}>
      {/* <Card.Head image={image} alt="property" /> */}
      <div className={hit?.status === "Sold Subject To Contract" || hit?.status === "Let Agreed" ? "property-box__badge yellow" : "property-box__badge"}>{x?.status}</div>
      <div className="overflow-hidden">
                <div className="hover-zoom">
      <ImageTransform
                imagesources={x?.images[0]?.url}
                // renderer="srcSet"
                imagename="property.images.similardetail"
                attr={{ alt: `${hit.display_address} - Strakers Estate Agents`, className: "" }}
                imagetransformresult={processedImages}
                id={x.id}
              />
              </div>
              </div>
    </Link>
    <Card.Body>
      <Row className="g-0 align-items-center">
        <Col>
        {hit?.extra?.lotNumber &&
          <h3>Lot {hit?.extra?.lotNumber}</h3>
        }
        </Col>
        <Col xs="auto">
          <p className="fw-bolder non-bottom">{moment(hit?.extra?.auctionDate).format("DD MMMM YYYY")}</p>
        </Col>
      </Row>

      <div className="card-let__price">
        <span className="text-uppercase d-inline-block">
          *Guide Price £ <span className="fw-bolder">{hit?.price}</span>+ Plus Fees
        </span>
      </div>
      <h4>
        <Link href={`/${uriStr}${hit.slug}-${hit.id}/`}>
          {hit.display_address}
          </Link>
      </h4>
      {/* {hit.title && <p className="card-let__description">{hit.title}</p>} */}
    </Card.Body>

    <Card.Footer>
      <Row className="g-0 justify-content-between">
        <Col>
          <LinkIcon href={`https://strakers.azurewebsites.net/auctions?lid=${hit?.crm_id}`} Link_Type={"External_url"} text="Details/Bid" icon="eye" />
        </Col>

        <Col>
        {hit.extra?.legalDocumentUrl &&
          <LinkIcon href={hit.extra?.legalDocumentUrl} Link_Type={"External_url"} text="Legal Docs" icon="file" />
        }
        </Col>
      </Row>
    </Card.Footer>
  </Card>
        )
      }),
    [propItems]
  )

  return (
    propertiesCollection?.length > 0 ?
    <div className="home-properties pt-48 pb-48 pt-120 pb-120 auctions">
      <Container className="home-properties__inner">
        {props.Cap_Title &&<h6 className="home-properties__quote fw-bolder fz14 d-inline-block">
          {props.Cap_Title}
        </h6>
        }

        <Row className="align-items-center align-items-lg-start justify-content-lg-between">
          <Col xs={12} lg="auto">
            <h3>{props.Title}</h3>
          </Col>

          <Col lg="auto">
            <Row className="flex-nowrap home-properties__actions">
              {props?.listname === "Current_auctions" &&
              <Col xs="auto" className="home-properties__action-col-1">
                <Link class="link-icon blue-link-hover d-flex align-items-center"
                  to={PAGE_AUCTION_CURRENT?.alias}
                >
                  <div
                    className={`link-icon__text fw-bolder fz14 position-relative order-0`}
                  >
                    View more lots
                  </div>
                  <i className={classNames(`icon icon--arrow-up-right`)}></i>
                </Link>
                {/* <LinkIcon href="/" text="Property for sale" /> */}
              </Col>
}
{props?.listname === "Past_auctions" &&
              <Col xs="auto">
                <Link class="link-icon blue-link-hover d-flex align-items-center"
                  to={PAGE_AUCTION_OLD?.alias}
                >
                  <div
                    className={`link-icon__text fw-bolder fz14 position-relative order-0`}
                  >
                    More previous auctions
                  </div>
                  <i className={classNames(`icon icon--arrow-up-right`)}></i>
                </Link>
                {/* <LinkIcon href="/" text="Property to rent" /> */}
              </Col>
}
            </Row>
          </Col>
        </Row>
      </Container>
      {/* {JSON.stringify(propItems)} */}
      {propertiesCollection?.length > 0 &&
      <Carousel
        className="home-properties__collection"
        data={propertiesCollection}
        controllers={{ buttons: isMobile && propertiesCollection?.length > 1 ? true : !isMobile && propertiesCollection?.length > 4 ? true : false }}
        isFluidContainer={false}
      />}
      
        <Modal className="teamdetails popupForm" backdrop="static" size="lg" show={showForm} onHide={() => { setShowForm(false);setIndex() }}>
                                            <Modal.Header closeButton>
                                                <Modal.Title> <div className="form__head">
          <h4>Book A Viewing</h4>
          {/* {JSON.stringify(index)}
          {index?.searchtype} */}
        </div>
</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body> 
                                                <BookAViewingForm
                                                id={index?.id}
                                                 property_img={index?.images && index?.images[0]?.url}
                                                  page_url={`${process.env.GATSBY_SITE_URL}${index?.search_type === "sales" && index?.department === "residential" ? "property-for-sale/" : "property-to-rent/"}${index?.slug}-${index?.id}`}
                                                   property_title={index?.display_address}
                                                    type={index?.search_type}
                                                data={index}
                                                />  
                                            </Modal.Body>
</Modal>
    </div>
 : isCLient ? <div className="empty-space"><br/></div>
 : null )
}

export default AuctionProperties
