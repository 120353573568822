/**
 * External dependencies.
 */
import React, { useRef, useState } from "react"
import { Link } from "gatsby"
import { Container } from "react-bootstrap"
import { useEffect } from "react"
import { useCallback } from "react"
import classNames from "classnames"
import { graphql } from "gatsby"
/**
 * Internal dependencies.
 */
import useBuyersGuideSteps from "./use-buyers-guide.steps"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Step from "../step/step"
import { convertGatsbyImageNodes } from "../../utils/gatsby-images"
import stepsData from "./static/steps"
import "./styles/_index.scss"
import ContentBlock from "../content/Content";
import GetURL from "../../components/common/site/get-url"
const BuyersGuideSteps = (props) => {
  const [lineStyle, setLineStyle] = useState(0)
  const lastItemRef = useRef()
  const data = useBuyersGuideSteps()
  const images = convertGatsbyImageNodes(data)
  const isTouchDevice = useCallback(() => {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    )
  }, [])

  const handleResize = useCallback(() => {
    if (isTouchDevice && window.innerWidth < 769) {
      setLineStyle(lastItemRef.current?.clientHeight - 40)
    } else if (isTouchDevice && window.innerWidth < 980) {
      setLineStyle(lastItemRef.current?.clientHeight - 40)
    } else {
      setLineStyle(lastItemRef.current?.clientHeight - 40)
    }
  }, [isTouchDevice])

  useEffect(() => {
    if (lastItemRef.current) {
      handleResize()
    }
  }, [lastItemRef, handleResize])

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return handleResize
  }, [handleResize])

  return (
    <div className="buyers-guide-steps pt-48 pb-48 pt-120 pb-120">
      <Container>
        <div className="buyers-guide-steps__inner">
          {props?.Add_Step?.map((x, index) => (
            <Step className={classNames({
              'mb-0': index === props?.Add_Step?.length - 1
            })} step={index + 1} key={index} customref={lastItemRef}>
              {x.Image &&
              <Step.Image>
                <div className="gatsby-image-wrapper">
                  {/* <img src={x.Image?.url} alt={x.Image?.alternativeText} /> */}
                  <GatsbyImage image={getImage(x.Image?.url_sharp)} alt={x.Image?.alternativeText?x.Image?.alternativeText+" - Strakers Estate Agents":
                x.Title+" - Strakers Estate Agents"
                } />
                </div>
              </Step.Image>
              }
              <Step.Content>
                {x.Title &&<h4>{x.Title}</h4> }
                {x.Content && <ContentBlock Content={x.Content} />}
                {x?.CTA_LInk?.Url?.Alias &&
                <GetURL
                class="step__link d-lg-flex blue-link-hover align-items-lg-center"
                label={""} customLink={x?.CTA_LInk?.Link} alias={x?.CTA_LInk?.Url?.Alias} >
                  <span>{x?.CTA_LInk?.Label}</span>
                  <i className="icon icon--arrow-diagonal"></i>
                </GetURL>
                }
              </Step.Content>
            </Step>
          ))}
          {/* {lastItemRef.current?.clientHeight} */}
          <span
            className="line"
            style={{
              bottom: lineStyle + "px",
            }}
          />
        </div>
      </Container>
    </div>
  )
}

export default BuyersGuideSteps
// export const stepperFragment = graphql`
//     fragment StepperFragment on GLSTRAPI_ComponentComponentStepper {
//         id
//         Add_Step {
//           Content
//           Title
//           Image {
//             alternativeText
//             url
//           }
//           CTA_LInk {
//             Label
//             Url {
//               Alias
//             }
//           }
//         }

//     }
// `