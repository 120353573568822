/**
 * External dependencies
 */
 import React, { useState, useEffect, useMemo } from "react"
import { Container, Row, Col } from "react-bootstrap"
import Carousel from "../carousel/carousel"
import classNames from "classnames"
import { Link } from "gatsby"
/**
 * Internal dependencies
 */
import lands from "./static/lands"
import CardLand from "./card-land"
import useNewsLand from "./use-news-land"
import { convertGatsbyImageNodes } from "../../utils/gatsby-images"
import "./styles/_index.scss"
import { useStaticQuery, graphql } from "gatsby";
import {
  REVIEWS_PAGE_URL
} from "../common/site/constants"
import {
  isIOS,
  isMobile
} from "react-device-detect";
const Awards = ({Awards, Cap_Title, Title, Cta_Link, Pagename}) => {
  const propertiesCollection = useMemo(
    () =>
    Awards.map((x, index) => {
      let processedImages = JSON.stringify({})
      if (x.imagetransforms?.Award_Image_Transforms) {
        processedImages = x.imagetransforms.Award_Image_Transforms
      }
        return ( 
          <Col
            key={x.Name}
            className={classNames("news-land__col", {
              // "d-none d-lg-block": index >= 2,
            })}
          >
            <CardLand
            isReview={true}
              imagetransforms={processedImages}
              id={x.id}
                image={x.Award_Image}
                // video={x.Video_Url}
                title={x.Title}
                href={x.Url ? x.Url : false}
                designation={x.Award_Name}
                index={index}
                imgname={"award.Award_Image.list"}
            />
          </Col>
        )
      }),
    [Awards]
  )
  return (
    Awards?.length > 0 ?
    <div className="home-news awards pt-24 pb-24  pt-48 pb-48 pt-120 pb-120">
    <Container>
      {Cap_Title &&
        <h6 className="home-news__heading fw-bolder fz14">{Cap_Title}</h6>
      }
        <Row className="home-news__head-row g-0 align-items-md-center align-items-lg-start">
          <Col>
          <h3>{Title}</h3>
          </Col>
          {/* {Pagename !== "Reviews" &&
          <Col xs={12} md="auto">
          <Link             className="link-icon blue-link-hover d-flex align-items-center"
                            
                            to={REVIEWS_PAGE_URL.alias}
                          >
                             <div
      className={`link-icon__text fw-bolder fz14 position-relative order-0`}
    >
      More Reviews
    </div>
    <i className={classNames(`icon icon--arrow-up-right`)}></i>
                          </Link>
          </Col>
           } */}
        </Row>
        </Container>
    <Container className={Awards?.length > 3 ? "news-land four paading-bot-no" : "news-land paading-bot-no"}>
      <Row className="news-land__row flex-column flex-md-row">
      {Awards?.length > 0 &&
          <Carousel
          className="customer-review-slider"
          data={propertiesCollection}
          controllers={{ buttons: isMobile && Awards?.length > 1 ? true : !isMobile && Awards?.length > 3 ? true : false }}
          isFluidContainer={false}
        />
        }
      </Row>
    </Container>
    </div>
  : null
  )
}

export default Awards
export const componentAwardsFragment = graphql`
    fragment ComponentAwardsFragment on GLSTRAPI_ComponentComponentAwards {
      id
      Title
          Cap_Title
          Awards {
            id
            imagetransforms
            Award_Name
            Title
            Publish
            Url
            Award_Image {
              alternativeText
              url
            }
          }

    }
`