/**
 * External dependencies.
 */
import React, { useMemo } from "react"
import moment from "moment"
import { Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import classNames from "classnames"

/**
 * Internal dependencies.
 */
import "./styles/_index.scss"

const Review = ({ children, title, author, date, className }) => {
  // const fromNow = useMemo(() => moment(date, "MM-DD-YYYY"), [date])

  return (
    <Col
      className={classNames("review flex-shrink-1", className)}
      xs="12"
      lg="6"
    >
      <div className="review__inner">
        {/* <div className="review__stars">
          <StaticImage
            layout="constrained"
            src="../../images/stars.png"
            alt="stars"
          />
        </div> */}
        <p className="review__title mb-1_2">
          <strong>{title}</strong>
        </p>
        {children}
        <div className="review__author">
          <h5 className="mb-1">{author}</h5>
          <p className="body-s mb-0">{moment(date, "MM-Do-YYYY").format("Do MMMM YYYY")}</p>
        </div>
      </div>
    </Col>
  )
}

export default Review
