/**
 * External dependencies
 */
 import { useStaticQuery, graphql } from "gatsby";

 const useValuationLandingIntro = () => {
   const { file } = useStaticQuery(graphql`
      query ValuationLandingIntro {
       file(relativePath: {eq: "valuation-landing-intro/images/bg.jpg"}) {
         id
         childImageSharp {
           gatsbyImageData(layout: FULL_WIDTH)
         }
       }
      }
    `)
 
   return file;
 }
 
 export default useValuationLandingIntro
 