/**
 * External dependencies
 */
 import { useStaticQuery, graphql } from "gatsby"

 const useBuyersGuideSteps = () => {
   const {
     allFile: { nodes: items },
   } = useStaticQuery(graphql`
     query BuyersGuideSteps {
       allFile(
         filter: { relativeDirectory: { eq: "buyers-guide-steps/images" } }
       ) {
         nodes {
           relativePath
           childImageSharp {
             gatsbyImageData(layout: FULL_WIDTH)
           }
         }
       }
     }
   `)
 
   return items
 }
 
 export default useBuyersGuideSteps
 